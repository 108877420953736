import {combineReducers, configureStore} from '@reduxjs/toolkit';
import addressAutoCompleteReducers from "./reducers/addressAutoComplete.reducer";
import publicWorkReducers from "./reducers/publicWork.reducer";

const rootReducer = combineReducers({
    addressAutoComplete: addressAutoCompleteReducers,
    publicWork: publicWorkReducers
})
export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
    reducer: rootReducer
});

export default store;
