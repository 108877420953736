import React from "react";
import './index.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {PublicWorkListFilter} from "../filter";
import { MAP_CENTER, MAP_URI, STATUS } from "../../../constants";
import {GeoJSON, Marker, TileLayer, Tooltip, useMap, useMapEvents} from "react-leaflet";
import MapComponent from "../../mapComponent";
import {drawFeatures} from "../../../hooks/drawGeojson";
import {MapPin} from "../../mapComponent/icons/MapPin";
import MapTooltip from "./map-tooltip";
import {SzLoadSpinner} from "../../utile/SzSpinner";
import L from "leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster';

interface IPublicWorkMap {
    goToDetail: (publicWorkId?: string) => void,
    createHandle: () => void,
    filter: { query: string, startDate: Date, endDate:Date },
    setFilter: (filter: { query: string, startDate: Date, endDate:Date }) => void,
    tab:string,
    geoserver:{
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?:GeoJSON.Feature,
    setMapData : (page?:number, localFilters?: { query: string, startDate: Date, endDate:Date }|null)=> void,
    loader: boolean
}

export const PublicWorkMap: React.FC<IPublicWorkMap> = ({goToDetail, createHandle, setFilter, filter, tab, geoserver, geoShape, setMapData, loader}) => {
    const listData = useSelector((state: RootState) => state.publicWork.list.data);
    const mapData = useSelector((state: RootState) => state.publicWork.mapData);
    const zooms = {minZoom: 6, maxZoom: 19, zoom: 6}

    const drawMarker = (id: string, currentStatus:string,latitude?: number, longitude?: number) => {
        const markerItem = listData.find((item: any) => item.id == id);
        if(!markerItem) {
            return null;
        }

        const tooltipData = {
            title: markerItem.name,
            status: markerItem.currentStatus,
            nature: markerItem.nature.label,
            startDate: markerItem.startDate,
            endDate: markerItem.endDate
        }

        return latitude && longitude && markerItem && (
            <Marker 
                key={id}
                position={[latitude, longitude]} 
                icon={MapPin(currentStatus)} 
                eventHandlers={{click: () => goToDetail(id)}}
            >
                <Tooltip direction="top" offset={[0, -30]} opacity={1}>
                    <MapTooltip {...tooltipData} />
                </Tooltip>
            </Marker>
        )
    }

    const createClusterCustomIcon = (cluster: any) => {
        let className = 'new-status';
        const markers = cluster.getAllChildMarkers();
        let displayStatus = {
            new: false,
            refuse: false,
            valid: false,
            inProgress: false,
            coming: false,
            realized: false,
            closed: false
        }

        markers.map((marker: L.Marker) => {
            const icon = marker.getIcon();

            Object.entries(STATUS).map((status) => {
                const value = status[1];
                if (undefined !== icon.options.className && icon.options.className.indexOf(value) > 0) {
                    displayStatus[value as keyof typeof displayStatus] = true;
                }
            })
        });

        if (displayStatus.new) {
            className = "new-status"
        } else if (displayStatus.refuse) {
            className = "refuse-status"
        } else if (displayStatus.valid) {
            className = "valid-status"
        } else if (displayStatus.coming) {
            className = "coming-status"
        } else if (displayStatus.inProgress) {
            className = "inProgress-status"
        }else if (displayStatus.realized) {
            className = "realized-status"
        } else if (displayStatus.closed) {
            className = "closed-status"
        }

        return L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className: "marker-cluster-custom public-work-status " + className,
            iconSize: L.point(28, 28, true),
        });
    };

    return (
        <div>
            <PublicWorkListFilter createHandle={createHandle} initData={setMapData} filter={filter}/>

             <div className='ml-3 mr-3 mt-0 map-list-public-work spinner-container'>

                <SzLoadSpinner loader={loader}  variant='danger'/>

                <MapComponent zooms={{...zooms}} center={MAP_CENTER} geoserver={geoserver} geoShape={geoShape} changeMarker={false} >
                    <TileLayer
                        url={MAP_URI}
                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {
                        mapData.map((work:{geoJson:any})=> drawFeatures(work.geoJson))
                    }
                    <MarkerClusterGroup
                        iconCreateFunction={createClusterCustomIcon}
                    >
                    {
                        mapData.map((work: { address: { latitude: number, longitude: number }, id: string, currentStatus: string }) => drawMarker(work.id, work.currentStatus, work.address?.latitude, work.address?.longitude))
                    }
                    </MarkerClusterGroup>
                </MapComponent>
            </div>
        </div>
    );
};
