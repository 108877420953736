import axios from "axios";
import { config } from '../config';
import { setupCache } from 'axios-cache-adapter';

export class Request {
  public static cancel: any;
  private base: string;
  private CancelToken: any;
  private cache: any;
  private onFullFilled?: (response?: any) => any;
  private onError?: (error?: any) => any;
  private beforeRequest?: () => any;

  constructor(
    beforeRequest?: () => any,
    onFullFilled?: (response?: any) => any,
    onError?: (error?: any) => any,
  ) {
    this.base = config.apiUrl as string;
    this.CancelToken = axios.CancelToken;
    this.cache = setupCache({
      maxAge: 15 * 60 * 1000,
    });

    this.onError = onError;
    this.onFullFilled = onFullFilled;
    this.beforeRequest = beforeRequest;
  }

  public async get(endpoint: string, cancelOption: boolean, cache:boolean= false) {
    Request.cancel && Request.cancel();
    const httpInstance = this.getHttpInstance(cache);
    let param = {};
    if (cancelOption) {
      param = {
        cancelToken: new this.CancelToken(function executor(c: any) {
          Request.cancel = c
        })
      }
    }
    return httpInstance.get(endpoint, param)
  }

  public async getBlob(endpoint: string) {
    Request.cancel && Request.cancel();
    const httpInstance = this.getHttpInstance();
    let param = { responseType: 'blob' as 'blob' };
    return httpInstance.get(endpoint, param)
  }

  public async getBlobWithPost(endpoint: string, params: any) {
    Request.cancel && Request.cancel();
    const httpInstance = this.getHttpInstance();
    params.responseType = 'blob' as 'blob';
    return httpInstance.post(endpoint, params)
  }

  public async post(endpoint: string, body: any, cancelOption?: boolean) {
    Request.cancel && Request.cancel();
    const httpInstance = this.getHttpInstance();
    let param = {};
    if (cancelOption) {
      param = {
        cancelToken: new this.CancelToken(function executor(c: any) {
          Request.cancel = c
        })
      }
    }
    return httpInstance.post(endpoint, body, param)
  }

  public async put(endpoint: string, body: any) {
    return this.getHttpInstance().put(endpoint, body)
  }

  public async delete(endpoint: string, body: any) {
    return this.getHttpInstance().delete(endpoint, body)
  }

  public createFormData(formData: FormData, key: string, data: any) {
    if (Array.isArray(data)) {
      let args: any = [];
      for (const i in data) {
        if (data[i] !== null) {
          args[i] = data[i]['value'];
        }
      }
      formData.append(key, args.join(','));
    } else if (data === Object(data)) {
      formData.append(key, data['value']);
    } else if (data !== null) {
      formData.append(key, data);
    }
  }

  public bodyToFormData(body: any) {
    const formData = new FormData();
    Object.keys(body).map((key: any) => {
      return this.createFormData(formData, key, body[key]);
    });
    return formData;
  }

  public async refreshToken() {
    const body = JSON.stringify({
      refresh_token: sessionStorage.getItem("refreshToken"),
    });
    const endpoint = `/token/refresh`;
    const httpInstance = this.getHttpInstance();
    return httpInstance.post(endpoint, body);
  }

  private getHttpInstance(
    cached?: boolean,
    showLoading = true,
    disbaleAutorisation = false,
  ) {
    const { base } = this;
    let adapter = this.cache.adapter;

    if ('undefined' !== typeof cached && !cached) {
      adapter = undefined;
    }

    if (showLoading) {
      this.beforeRequest?.();
    }

    const headers: any = {
      'Content-Type': 'application/json;charset=utf-8',
    };

    if (!disbaleAutorisation) {
        headers.authorization = `Bearer ${localStorage.getItem("token-portal-extranet")}`;
    }

    const httpInstance = axios.create({
      adapter,
      baseURL: `${base}`,
      headers
    });

    httpInstance.interceptors.response.use(
      async (response) => {
        this.onFullFilled?.(response);

        return response;
      },
      (error) => {
        this.onError?.(error);

        return Promise.reject(error);
      },
    );
    return httpInstance;
  }
}

export default new Request();
