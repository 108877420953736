import instanceRequest, {Request} from "./Request";

class AttachmentService {

    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public postAttachments(attachment: File) {
        const url = `/api/public-work/attachment`;
        const data = new FormData();
        data.append("attachment", attachment);
        return this.axiosService.post(url, data).then((response) => {
            return response;
        });
    }

    public getAttachment(publicWorkId?: string, id?: string) {
      const url = `/api/public-work/${publicWorkId}/attachment/${id}`;
      return this.axiosService.getBlob(url);
    }
}

export default new AttachmentService(instanceRequest);
