import React from "react";
import { SzTypographie } from "@suezenv/react-theme-components";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import {formatDate} from "../../../utile";
import "./map-tooltip.scss";

interface IMapTooltip {
    title: string,
    status: string,
    nature: string,
    startDate: string,
    endDate: string,
}
const MapTooltip: React.FC<IMapTooltip> = ({ title, status, nature, startDate, endDate }) => {
    const trans = useTranslationWithPrefix("publicWork", "view_public_work");
    const tranStatus = useTranslationWithPrefix("publicWork", "public_work_status");

    return (
        <div className="row map-tooltip">
            <div className="col mx-0 mt-0">
                <div className="title p-2 m-0">
                    <SzTypographie variant="body" weight="bold" className="m-0">
                        {title}
                    </SzTypographie>
                </div>
                <div className="row p-2">
                    <div className="col">
                        <div className="row  mb-2">
                            <div className="col">
                                <SzTypographie variant="caption">{trans("status")}</SzTypographie>
                                <SzTypographie variant="body" weight="medium" className="mb-2">
                                    {tranStatus(status)}
                                </SzTypographie>
                            </div>
                        </div>

                        <div className="row  mb-2">
                            <div className="col">
                                <SzTypographie variant="caption">{trans("nature")}</SzTypographie>
                                <SzTypographie variant="body" weight="medium" className="mb-2">
                                    {nature}
                                </SzTypographie>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <SzTypographie variant="caption">{trans("startDate")}</SzTypographie>
                                <SzTypographie variant="body" weight="medium" className="mb-2">
                                    {formatDate(startDate, 'DD/MM/YYYY')}
                                </SzTypographie>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <SzTypographie variant="caption">{trans("endDate")}</SzTypographie>
                                <SzTypographie variant="body" weight="medium" className="mb-2">
                                    {formatDate(endDate, 'DD/MM/YYYY')}
                                </SzTypographie>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MapTooltip;
