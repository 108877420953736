import React from "react";
import {SzData, SzTypographie} from "@suezenv/react-theme-components";
import {PublicWork} from "../../../services/PublicWorkService";
import {formatDate} from "../../../utile";

interface ICommentList {
    currentPublicWork?:PublicWork
}

export const CommentList: React.FC<ICommentList> = ({currentPublicWork}) => {

    return (
        <React.Fragment>
            {
                currentPublicWork && currentPublicWork.comments.map((comment, key: number) => {
                    return (
                        comment &&
                        <div key={key}>
                            <SzData icon="people-man-1">
                                <SzData.Text>{formatDate(comment.createdAt, 'DD/MM/YYYY HH:mm:ss')}</SzData.Text>
                                <SzData.Title>{comment.createdBy}</SzData.Title>
                            </SzData>
                            <SzTypographie weight="light" className="mb-3 pl-4 ml-3">
                                {comment.comment}
                            </SzTypographie>
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}