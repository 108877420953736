import React from "react";
import MapDivIconGenerator from "../../utile/icon.util";
import ValidPinWork from "./svg/status/ValidPinWork";
import ComingPinWork from "./svg/status/ComingPinWork";
import RealizedPinWork from "./svg/status/RealizedPinWork";
import NewPinWork from "./svg/status/NewPinWork";
import InProgressPinWork from "./svg/status/InProgressPinWork";
import ClosedPinWork from "./svg/status/ClosedPinWork";
import RefusePinWork from "./svg/status/RefusePinWork";

export const MapPin = (status: string, className = 'create-work ') => {
    let statusIcon = <NewPinWork/>;
    switch (status) {
        case "new":
            className += "new-status";
            statusIcon = <NewPinWork/>;
            break
        case "valid":
            className += "valid-status";
            statusIcon = <ValidPinWork/>;
            break
        case "coming":
            className += "coming-status";
            statusIcon = <ComingPinWork/>;
            break
        case "closed":
            className += "closed-status";
            statusIcon = <ClosedPinWork/>;
            break
        case "inProgress":
            className += "inProgress-status";
            statusIcon = <InProgressPinWork/>;
            break
        case "refuse":
            className += "refuse-status";
            statusIcon = <RefusePinWork/>;
            break
        case "realized":
            className += "realized-status";
            statusIcon = <RealizedPinWork/>;
            break
        default:
            statusIcon = <NewPinWork/>;
    }

    return new MapDivIconGenerator(className, [25, 25], [15, 40]).generateDivIcon(
        statusIcon)
}

