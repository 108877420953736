import {Constants} from "../../constants";

const initialState: any = {
    currentPublicWork: {
        organizationId: null,
        organizationName: null,
        categoryId: null,
        categoryName: null,
        contact: {
            firstName: null,
            lastName: null,
            mail: null,
            phone: null
        },
        name: null,
        nature: {
            id: null,
            label: null
        },
        additionalInformation: null,
        startDate: null,
        updatedAt: null,
        endDate: null,
        address: {
            fullAddress: null,
            city: null,
            street: null,
            number: null,
            postCode: 0,
            country: null,
            latitude: 0,
            longitude: 0
        },
        geoJson: null,
        diDictNumber: null,
        createdBy: null,
        comment:[],
        attachments: null,
        currentStatus:null
    },
    list: {
        data: [],
        pagination: []
    },
    mapData:[]
}
const publicWorkReducers = (state = initialState, action: any): any => {
    switch (action.type) {
        case Constants.UPDATE_CURRENT_PUBLIC_WORK:
            return {...state, currentPublicWork: {...state.currentPublicWork, ...action.payload}};
        case Constants.INIT_CURRENT_PUBLIC_WORK:
            return {...state, currentPublicWork: {...initialState.currentPublicWork}};
        case Constants.SET_LIST_PUBLIC_WORK:
            return {...state, list: {...action.payload}};
        case Constants.SET_MAP_PUBLIC_WORK:
            return {...state, mapData: [...action.payload]};

        default:
            return state;
    }
}
export default publicWorkReducers