import React, {useState} from "react";
import {SzAlert, SzButton, SzInput, SzTypographie} from "@suezenv/react-theme-components";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import {Form, Formik} from "formik";
import {SzDropZone} from "../../utile/SzDropZone";
import {ACCEPT_FILES_MIME} from "../../../constants";
import {getFormatedForm, step4Model} from "../../../Models";
import {useDispatch, useSelector} from "react-redux";
import {updatePublicWork} from "../../../store/actions/publicWork";
import {PublicWork} from "../../../services/PublicWorkService";
import AttachmentService from "../../../services/AttachmentService";
import {RootState} from "../../../store";
import {IAttachment} from "../../../types";


interface IPublicWorkCreateStep4 {
    nextHandle: () => void
    previousHandle: () => void,
    currentPublicWork?: PublicWork
}


const MAX_UPLOAD_FILES = 50;

export const PublicWorkCreateStep4: React.FC<IPublicWorkCreateStep4> = ({nextHandle, previousHandle, currentPublicWork}) => {
    const trans = useTranslationWithPrefix("publicWork", "public_work_create");
    const [schema] = getFormatedForm(step4Model);
    const dispatch = useDispatch();
    const [listAttachments, setListAttachments] = useState<IAttachment[]>(currentPublicWork ? currentPublicWork.attachments || [] : []);
    const [loader, setLoader] = useState(false);


    const submitStep = ({diDictNumber}: PublicWork) => {
        if(!loader) {
            dispatch(updatePublicWork({diDictNumber, attachments: JSON.stringify(listAttachments)}));
            nextHandle();
        }
    }

    const deleteFileHandle = (files: IAttachment[]) => {
        setListAttachments(files);
    };
    const dropHandle = (files: any) => {
        let nbFilesLoaded = 0;

        files.forEach((file: File) => {
            setLoader(true);
            AttachmentService.postAttachments(file).then((response: any) => {

                const uploadedFile = response.data;
                if (uploadedFile.id && uploadedFile.originalFileName && uploadedFile.tmpDir) {
                    listAttachments.push(uploadedFile);
                }

            }).finally(()=>{
                nbFilesLoaded ++;
                if(nbFilesLoaded >= files.length)
                {
                    setLoader(false);
                }
            });
        });
        setListAttachments(listAttachments);

    };

    return (
        <Formik onSubmit={() => {
        }} initialValues={{
            diDictNumber: currentPublicWork?.diDictNumber ? currentPublicWork.diDictNumber : '',
        }}
                validationSchema={schema}>
            {(formikProps: any) => {
                const {values, setFieldValue, errors} = formikProps;
                const {
                    diDictNumber,
                } = values;

                return (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <div className="public-work-box mt-3">
                            <SzTypographie variant="h1" className='m-0 mb-3 ml-5'>
                                {trans('detail_title')}
                            </SzTypographie>
                            <div className="row ml-5">
                                <div className="col-4 pl-0 pt-3">
                                    <SzInput
                                        placeholder=''
                                        label={trans("diDict_label")}
                                        value={diDictNumber}
                                        onChange={(e) => {
                                            setFieldValue("diDictNumber", e.target.value);
                                        }}
                                    />
                                    {errors['diDictNumber'] && (
                                        <SzAlert variant="danger">{trans(errors['diDictNumber'])}</SzAlert>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="public-work-box mt-3">

                            <div className="row">
                                <div className="col-8 offset-2">
                                    <SzDropZone
                                        initFiles={listAttachments}
                                        className="multi-Drop-zone"
                                        maxFiles={MAX_UPLOAD_FILES}
                                        dropHandle={dropHandle}
                                        deleteHandle={deleteFileHandle}
                                        multiple={true}
                                        placeHolder={trans('drop_files')}
                                        accept={ACCEPT_FILES_MIME}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <SzButton  variant="secondary" className="float-left"
                                      onClick={previousHandle}>{trans('back')}</SzButton>
                            <SzButton loader={loader} className="float-right"
                                      onClick={() => submitStep(values)}>{trans('next')}</SzButton>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    )
}