import React from "react";
import {PublicWork} from "../utile/PublicWork";
import "./index.scss";
import {OrganizationType} from "../../types";
import {UserType} from "../../types/user.type";
import {CreateContent} from "./CreateContent";

interface IPublicWorkCreate {
    organizations: OrganizationType[]
    goToHomeHandle: () => void,
    user: UserType,
    geoserver:{
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?:GeoJSON.Feature,
    workId?:string
}

export const PublicWorkCreate: React.FC<IPublicWorkCreate> = ({organizations, goToHomeHandle, geoserver, user, geoShape, workId}) => {
    return (
        <PublicWork>
            <CreateContent user={user} organizations={organizations} goToHomeHandle={goToHomeHandle} geoserver={geoserver}  geoShape={geoShape} workId={workId} />
        </PublicWork>
    );
};