import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
    GeoJSON,
    Marker,
    TileLayer, useMap,
    useMapEvents,
} from 'react-leaflet'
import {MAP_CENTER, MAP_URI} from "../../../constants";
import MapComponent from "../../mapComponent";
import {DragEndEvent, LatLngExpression} from "leaflet";
import MapViewChanger from "../../mapComponent/MapViewChanger";
import {FeatureCollection, Position} from "geojson";
import {drawFeatures} from "../../../hooks/drawGeojson";

interface IPublicWorkCreateStep3Map {
    mapPosition: LatLngExpression | null
    markerChangeHandle: (lat: number, lng: number) => void
    showEditControl: boolean
    geoJson: FeatureCollection  | null,
    workStatus?:string
    geoserver:{
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?:GeoJSON.Feature,
}

export const PublicWorkCreateStep3Map: React.FC<IPublicWorkCreateStep3Map> = ({mapPosition, markerChangeHandle, showEditControl, geoJson, geoserver, geoShape, workStatus}) => {
    const [zooms, setZooms] = useState({minZoom: 6, maxZoom: 19, zoom: mapPosition ? 18 : 6});

    return (
        <MapComponent workStatus={workStatus} zooms={{...zooms}} center={MAP_CENTER} geoserver={geoserver} geoShape={geoShape} markerChangeHandle={markerChangeHandle} mapPosition={mapPosition} changeMarker={true} >
            <TileLayer
                url={MAP_URI}
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {drawFeatures(geoJson)}

        </MapComponent>
    )
}