import { Constants } from "../../constants";

// actions
export const updatePublicWork = (payload: any) => ({
    type: Constants.UPDATE_CURRENT_PUBLIC_WORK,
    payload: payload
});

export const initPublicWork = () => ({
    type: Constants.INIT_CURRENT_PUBLIC_WORK,
});

export const setListPublicWork = (payload: any) => ({
    type: Constants.SET_LIST_PUBLIC_WORK,
    payload: payload
});


export const setMapPublicWork = (payload: any) => ({
    type: Constants.SET_MAP_PUBLIC_WORK,
    payload: payload
});


