import React from "react";
import {SzSpinner} from "@suezenv/react-theme-components";
import './index.css';

interface ISzSpinner {
    className?: string;
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | undefined;
    loader: boolean;
}

/**
 * Add className '.spinner-container' to the parent div
 * @param props
 */
export const SzLoadSpinner = (props: ISzSpinner) => {
    const {variant, loader} = props;
    return (
        <div>
            {loader && (
                <div className='fade modal-backdrop show'>
                    <div className="modal-dialog-centered loading-spinner">
                        <SzSpinner variant={variant}/>
                    </div>
                </div>
            )}
        </div>
    );
}
