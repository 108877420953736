import React, {useEffect, useState} from 'react';
import {SzButton} from "@suezenv/react-theme-components";

import {useDropzone} from 'react-dropzone';
import classnames from 'classnames';
import {IAttachment} from "../../../types";

interface ISzDropZone {
    placeHolder: string,
    multiple: boolean,
    dropHandle: (filesList: IAttachment[]) => void,
    className: string,
    maxFiles?: number,
    accept: string [],
    deleteHandle: (filesList: IAttachment[]) => void,
    initFiles:IAttachment[]

}

export const SzDropZone = (props: ISzDropZone) => {
    const {placeHolder, multiple, dropHandle, accept, deleteHandle, className = "", maxFiles = undefined, initFiles} = props;
    const [listFiles , setlistFiles] = useState<IAttachment[]>(initFiles);

    useEffect(() => {
        if(initFiles.length == 0) {
            setlistFiles([]);
        }
    }, [initFiles]);

    const onDrop = (files: any[]) => {
        let filesList: IAttachment[] = multiple ? [...listFiles, ...files] : files;
        filesList = (maxFiles && filesList.length > maxFiles) ? filesList.slice(0, maxFiles) : filesList;
        dropHandle(files);
        setlistFiles(filesList);
    }
    const disabled = maxFiles ? maxFiles <= listFiles.length : false;
    const {getRootProps, getInputProps} = useDropzone({multiple, onDrop, disabled, accept: accept.toString()});
    const deleteFileHandle = (index: number) => {
        const newFiles = [...listFiles]
        newFiles.splice(index, 1);
        setlistFiles(newFiles);
        deleteHandle(newFiles);
    }
    const files = listFiles.map((file: IAttachment, index: number) => {
        // delete just not saved files
        const delLink = file.id?'':<SzButton variant="tertiary" className="mr-3" onClick={() => deleteFileHandle(index)} icon="bin-2-alternate"/>;
        return (<li key={index}>
            {file.name|| file.originalFileName|| ''} {delLink}
        </li>);
    });
    const classNames = classnames('dropzone', className)
    return (
        <div>
            <div {...getRootProps({className: classNames})}>
                <input {...getInputProps()} />
                <p><SzButton variant="tertiary" icon="picture-landscape" iconVariant="bold">{placeHolder}</SzButton></p>
            </div>
            <ul className="m-3 p-2 file-list">{files}</ul>
        </div>
    );
}
