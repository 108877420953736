import React, {useEffect, useState} from "react";
import {PublicWorkList} from "./list";
import {SzTab} from "@suezenv/react-theme-components";
import {PublicWorkMap} from "./map";
import {useTranslationWithPrefix} from "../../hooks/translation";
import {GeoJSON} from "react-leaflet";
import PublicWorkService from "../../services/PublicWorkService";
import {setListPublicWork, setMapPublicWork} from "../../store/actions/publicWork";
import {useDispatch} from "react-redux";


interface IPublicWorkTabs {
    createHandle: () => void,
    goToDetail: (publicWorkId?: string) => void,
    geoserver: {
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?: GeoJSON.Feature,
}

export const PublicWorkViewTabs: React.FC<IPublicWorkTabs> = ({ createHandle, goToDetail, geoserver, geoShape}) => {
    const defaultStartDate = new Date();
    defaultStartDate.setFullYear(defaultStartDate.getFullYear() - 1);

    const defaultEndDate = new Date();
    defaultEndDate.setFullYear(defaultEndDate.getFullYear() + 1);
    const [loader,setLoader] = useState(false);

    const [filter, setFilter] = useState({query: '', startDate: defaultStartDate, endDate: defaultEndDate});
    const [tab, setTab] = useState('tab-0');
    const trans = useTranslationWithPrefix("publicWork", "public_work");
    const dispatch = useDispatch();
    const selectTabHandle = (tab: string) => {
        setTab(tab)
    };
    useEffect(() => {
        setMapData();
        setListData();
    }, []);
    const setMapData = (page = 1, localFilters: { query: string, startDate: Date, endDate:Date } | null = null) => {

        if (localFilters) {
            setFilter({...localFilters});
        } else {
            localFilters = filter;
        }
        setLoader(true);
        PublicWorkService.getPublicWorks(page, localFilters, false).finally(()=>{
            setLoader(false);
        }).then((response) => {
            dispatch(setMapPublicWork(response.data));
        });
    }

    const setListData = (page = 1, localFilters: { query: string, startDate: Date, endDate:Date } | null = null) => {

        if(localFilters)
        {
            setFilter({...localFilters});
        }else
        {
            localFilters = filter;
        }

        setLoader(true);
        PublicWorkService.getPublicWorks(page, localFilters).then((response) => {
            dispatch(setListPublicWork(response));
            setLoader(false);

        }).finally(() => {
                setLoader(false);
            }
        );
    }

    return (

        <div className="work-tab">
            <SzTab
                activeKey={tab}
                id="story-tab"
                selectTabHandle={selectTabHandle}
            >
                <SzTab.SzTabContent eventKey="tab-0" title={trans('tab_map_title')}>
                    <PublicWorkMap loader={loader} setMapData={setMapData} goToDetail={goToDetail} filter={filter} setFilter={setFilter}
                                   createHandle={createHandle} tab={tab} geoShape={geoShape} geoserver={geoserver} />
                </SzTab.SzTabContent>
                <SzTab.SzTabContent eventKey="tab-1" title={trans('tab_list_title')}>
                    <PublicWorkList  loader={loader} goToDetail={goToDetail} filter={filter} setFilter={setFilter}
                                    createHandle={createHandle} tab={tab} setListData={setListData}/>
                </SzTab.SzTabContent>
            </SzTab>
        </div>
    );
};