import React from "react";
import {PublicWork} from "../utile/PublicWork";
import {DetailContent} from "./DetailContent";
import {UserType} from "../../types/user.type";


interface IPublicWorkDetail {
    goToHome: () => void,
    user: UserType,
    editHandle: (id?:string) => void,
    workId?: string,
    geoserver:{
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?:GeoJSON.Feature
}


export const PublicWorkDetail: React.FC<IPublicWorkDetail> = ({goToHome, workId, geoShape, geoserver, editHandle, user}) => {


    return (<React.Fragment> {workId &&
    <PublicWork>
        <DetailContent editHandle={editHandle} user={user} geoserver={geoserver}  geoShape={geoShape} workId={workId} goToHome={goToHome}/>
    </PublicWork>
    }
    </React.Fragment>)
}