import React, { useState } from "react";
import { SzDropZone } from "../../utile/SzDropZone";
import {ACCEPT_FILES_MIME} from "../../../constants";
import {IAttachment} from "../../../types";
import AttachmentService from "../../../services/AttachmentService";
import PublicWorkService from "../../../services/PublicWorkService";
import {PublicWork as IPublicWork, PublicWork} from "../../../services/PublicWorkService";
import {SzButton, SzCard} from "@suezenv/react-theme-components";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import {updatePublicWork} from "../../../store/actions/publicWork";
import { useDispatch } from "react-redux";

interface AddAttachments {
    currentPublicWork: PublicWork,
}

const MAX_UPLOAD_FILES = 50;

export const AddAttachments: React.FC<AddAttachments> = ({currentPublicWork}) => {
    const trans = useTranslationWithPrefix("publicWork", "public_work_create");
    const [listAttachments, setListAttachments] = useState<IAttachment[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [card, setCard] = useState<any>(null);
    
    const dispatch = useDispatch();

    const deleteFileHandle = (files: IAttachment[]) => {
        setCard(null);
        const filesNames = files.map(item => item.name);
        const attachments = listAttachments.filter(item => filesNames.includes(item.originalFileName));
        setListAttachments(attachments);
    };
    
    const dropHandle = (files: any) => {
        setCard(null);
        setIsLoading(true);
        const attachments = [...listAttachments];
        files.forEach((file: File) => {
            AttachmentService.postAttachments(file).then((response: any) => {
                setIsLoading(false);
                const uploadedFile = response.data;
                if (uploadedFile.id && uploadedFile.originalFileName && uploadedFile.tmpDir) {
                    attachments.push(uploadedFile);
                    setListAttachments(attachments);
                }
            }).catch(()=>{
                setIsLoading(false);
                setCard(<SzCard direction={'top'} color={'danger'} title={trans('error_on_file_send')}/>);
            });
        });
    };

    const sendAttachments = (currentPublicWork: IPublicWork, attachments : IAttachment[]) => {
        setIsLoading(true);
        PublicWorkService.postPublicWorkAttachments(currentPublicWork, attachments)
                        .then(() => {
                            // if attachment post is succeeded update public work in the global store
                            retrievePublicWork(currentPublicWork);
                        })
                        .catch(() => {
                            setIsLoading(false);
                            setCard(<SzCard direction={'top'} color={'danger'} title={trans('error_on_file_send')}/>);
                        });
    }

    const retrievePublicWork = (currentPublicWork: IPublicWork) => {
        if(currentPublicWork.id) {
            PublicWorkService
                            .getPublicWork(currentPublicWork.id)
                            .then((response) => {
                                dispatch(updatePublicWork({...response.data}));
                                setIsLoading(false);
                                setListAttachments([]);
                                setCard(<SzCard direction={'top'} color={'primary-brand'} title={trans('success_file_send')}/>);
                            })
                            .catch(() => {
                                setIsLoading(false);
                                setCard(<SzCard direction={'top'} color={'danger'} title={trans('error_on_file_send')}/>);
                            });
        }
        else {
            setIsLoading(false);
            setCard(<SzCard direction={'top'} color={'danger'} title={trans('error_on_file_send')}/>);
        }
    }

    return (
        <React.Fragment>
            
            <div className="row justify-content-center m-3">
                {card}
            </div>

            <div className="row">
                <div className="col-8 offset-2">
                    <SzDropZone
                        initFiles={listAttachments}
                        className="multi-Drop-zone"
                        maxFiles={MAX_UPLOAD_FILES}
                        dropHandle={dropHandle}
                        deleteHandle={deleteFileHandle}
                        multiple={true}
                        placeHolder={trans('drop_files')}
                        accept={ACCEPT_FILES_MIME}
                    />
                </div>
            </div>
            {
                <div className="row justify-content-center">
                    <SzButton
                        variant="secondary"
                        icon="send-email-1"
                        alignIcon="right"
                        loader={isLoading}
                        isDisabled={listAttachments.length ? false : true}
                        onClick={() => sendAttachments(currentPublicWork, listAttachments)}
                    >
                        {trans('send')}
                    </SzButton>
                </div>
            }
        </React.Fragment>
    )
}