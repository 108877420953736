import React, {useEffect, useState} from "react";
import {SzTable, SzButton, SzPagination} from "@suezenv/react-theme-components";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import './index.scss'
import  {PublicWork} from "../../../services/PublicWorkService";
import {
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT,
    PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT
} from "../../../constants";
import { useSelector} from "react-redux";
import {RootState} from "../../../store";
import {PublicWorkListFilter} from "../filter";
import {formatDate} from "../../../utile";
import {SzLoadSpinner} from "../../utile/SzSpinner";

interface IPublicWorkList {
    goToDetail: (publicWorkId?: string) => void,
    createHandle: () => void,
    filter: { query: string, startDate: Date, endDate:Date },
    setFilter: (filter:{ query: string, startDate: Date, endDate:Date }) => void,
    tab:string,
    setListData: (page?:number, localFilters?: { query: string, startDate: Date, endDate:Date }|null) => void,
    loader:boolean
}

interface IColumn {
    classes: string
    dataField: string
    text: string,
    headerClasses: string,
    headerEvents: {
        onClick: React.MouseEventHandler<HTMLButtonElement>
    }
}

interface IPublicWorkData {
    status: React.ReactNode,
    workId: string,
    actor: string,
    address: string,
    startDate: string,
    updatedAt: string,
    nature: React.ReactNode,
    action: React.ReactNode
}

export const PublicWorkList: React.FC<IPublicWorkList> = ({goToDetail, createHandle,setFilter, filter, tab, setListData, loader}) => {
    const trans = useTranslationWithPrefix("publicWork", "view_public_work");
    const tranStatus = useTranslationWithPrefix("publicWork", "public_work_status");
    const [columns, setColumns] = useState<IColumn[]>([]);
    const list = useSelector((state: RootState) => state.publicWork.list);
    useEffect(() => {
        setColumnsTable();
    }, [tab]);

    const setColumnsTable = () => {
        setColumns([
            columnJson("status", "status", "status", true),
            columnJson("title", "title", "title", true),
            columnJson("nature", "nature", "nature", true),
            columnJson("actor", "actor", "actor", true),
            columnJson("address", "address", "address", true),
            columnJson("updatedAt", "updatedAt", "updatedAt", true),
            columnJson("startDate", "startDate", "startDate", true),
            columnJson("action", "action", "", false)
        ]);
    }

    const columnJson = (
        classes: string,
        dataField: string,
        text: string,
        canSort = true,
    ) => {
        return {
            classes,
            dataField,
            text: text ? trans(text) : "",
            headerClasses: "",
            sort: canSort,
            headerEvents: {
                onClick: () => {
                }
            },
        };
    }

    const listPublicWork = (): IPublicWorkData[] => {
        const result: any = [];
        if (list.data) {
            list.data.forEach((item: PublicWork) => {
                result.push({
                    status: <div key={item.id} className="position-relative d-flex flex-row">
                        <div className={`info-status public-work-status ${item.currentStatus ||'new'}-status mt-1`}></div>
                        <div
                            className="ml-4">{tranStatus(item.currentStatus ||'new')}</div>
                    </div>,
                    title: item.name,
                    actor: item.organizationName,
                    address: item.address.fullAddress,
                    nature: truncateText(item.nature.label),
                    updatedAt: formatDate(item.updatedAt, 'DD/MM/YYYY'),
                    startDate: formatDate(item.startDate, 'DD/MM/YYYY'),
                    action: <SzButton key={item.id} onClick={() => goToDetail(item.id)} variant="tertiary" className="mr-3"
                                      icon="task-list-text-1"/>
                });
            });
        }
        return result;
    }

    const truncateText = (text: string|undefined, length: number|undefined = 30) => {
        if(typeof text == 'string') {
            const result = (text.length <= length) ? text : text.slice(0, length)+'...';
            return (<span title={text}>{result}</span>);
        }
        return null;
    }

    return (
        <div>
            <PublicWorkListFilter createHandle={createHandle} initData={setListData} filter={filter}/>
            <div className='ml-3 mr-3 mt-0'>
                {columns.length &&
                <div className='spinner-container'>
                    <SzLoadSpinner loader={loader} variant='danger' />

                    <SzTable
                        columns={columns}
                        data={listPublicWork()}
                        keyField="id"
                        className="public-work-table table-striped mt-2"
                    />
                    <div className="row w-100">
                        <div className="col-6 w-100 offset-5">
                            <SzPagination
                                totalItemsCount={parseInt(list.pagination[PAGINATION_TOTAL_COUNT], 10)}
                                activePage={parseInt(list.pagination[PAGINATION_CURRENT_PAGE], 10)}
                                onChange={(pageNumber: any) => {
                                    setListData(pageNumber);
                                }}
                                itemsCountPerPage={parseInt(list.pagination[PAGINATION_PER_PAGE], 10)}
                                pageRangeDisplayed={parseInt(list.pagination[PAGINATION_PAGE_COUNT], 10)}
                            />

                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
};