import React, {useEffect, useState} from "react";
import {SzButton, SzInput, SzTypographie, SzAlert} from "@suezenv/react-theme-components";
import i18n from "../../../i18n";
import {I18nextProvider} from "react-i18next";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import {Form, Formik} from "formik";
import Switch from "../../switch";
import SzSelectWithLabel, {Option} from "../../utile/SzSelectWithLabel";
import {OrganizationType} from "../../../types";
import {getFormatedForm, step1Model} from "../../../Models";
import {useDispatch, useSelector} from "react-redux";
import {updatePublicWork} from "../../../store/actions/publicWork";
import {UserType} from "../../../types/user.type";
import {RootState} from "../../../store";
import {PublicWork} from "../../../services/PublicWorkService";


interface IPublicWorkCreateStep1 {
    nextHandle: () => void
    organizations: OrganizationType[],
    user: UserType,
    currentPublicWork?:PublicWork
}


export const PublicWorkCreateStep1: React.FC<IPublicWorkCreateStep1> = ({nextHandle, organizations, user, currentPublicWork}) => {
    const dispatch = useDispatch();
    const trans = useTranslationWithPrefix("publicWork", "public_work_create");
    const [isValid, setIsValid] = useState(false);
    const [showError, setShowError] = useState(false);
    const [schema] = getFormatedForm(step1Model);

    const submitStep = ({organization, category, firstName, lastName, mail, phone}: { organization: string, category: string, firstName: string, lastName: string, mail: string, phone: string }) => {
        dispatch(updatePublicWork({
            contact: {firstName, lastName, mail, phone},
            organizationId: organization,
            organizationName: getOrganizationNameById(category, organization),
            categoryId: category,
            categoryName: getCategoryNameById(category, organization),
            createdBy: `${user.data?.firstName} ${user.data?.lastName}`
        }));

        nextHandle();
    }


    const getCategoryNameById = (categoryId: string, organizationId: string) => {
        let name = null;
        organizations.forEach((organization: OrganizationType) => {
            if (organization.id === organizationId) {
                organization.category.forEach((category) => {
                    if (category.id === categoryId) {
                        name = category.label;
                    }
                })
            }
        });

        return name;
    }


    const getOrganizationNameById = (categoryId: string, organizationId: string) => {
        let name = null;
        organizations.forEach((organization: OrganizationType) => {
            if (organization.id === organizationId) {
                name = organization.label;
            }
        });

        return name;
    }
    const getOrganizationOptions = () => {
        const options: Option[] = [];
        organizations.forEach((organization: OrganizationType) => {
            options.push({label: organization.label, value: organization.id})
        });
        return options;
    }

    const getCategoryOptions = (organizationId?: string) => {
        const options: Option[] = [];
        organizations.forEach((organization: OrganizationType) => {
            if (organization.id === organizationId) {
                organization.category.forEach((category) => {
                    options.push({label: category.label, value: category.id})
                })
            }
        });
        return options;

    }
    return (
        <I18nextProvider i18n={i18n}>
            <Formik  onSubmit={() => {
            }} initialValues={{
                organizationOption: currentPublicWork?.organizationId ? { label: currentPublicWork?.organizationName, value: currentPublicWork?.organizationId }:null,
                organization: currentPublicWork?.organizationId ? currentPublicWork?.organizationId :null,
                categoryOption:currentPublicWork?.categoryId ? { label: currentPublicWork?.categoryName, value: currentPublicWork?.categoryId }:null ,
                category:currentPublicWork?.categoryId ? currentPublicWork?.categoryId:null ,
                lastName:  currentPublicWork?.contact?.lastName || "",
                firstName: currentPublicWork?.contact?.firstName || "",
                mail: currentPublicWork?.contact?.mail || "",
                phone: currentPublicWork?.contact?.phone || ""
            }}
                    validationSchema={schema}>
                {(formikProps: any) => {
                    const {values, setFieldValue, errors, setFieldError, setErrors} = formikProps;
                    const {
                        organization,
                        organizationOption,
                        category,
                        categoryOption,
                        lastName,
                        firstName,
                        mail,
                        phone
                    } = values;

                    schema.isValid(values).then((isValidForm: any) => {
                        setIsValid(isValidForm);
                    });

                    return (

                        <Form onSubmit={formikProps.handleSubmit} >
                            <div className="public-work-box">
                                <SzTypographie variant="h1" className='m-0 mb-3 ml-5'>
                                    {trans('identification_title')}
                                </SzTypographie>
                                <div className="row">
                                    <div className="col-5 ml-5">
                                        <SzSelectWithLabel
                                            value={organization}
                                            defaultValue={organizationOption}
                                            options={getOrganizationOptions()}
                                            name="organization"
                                            onChange={(option: { label: string, value: string }) => {
                                                setFieldValue("organization", option ? option.value : organization?.value);
                                                setFieldValue("category", null);

                                            }}
                                            label={trans("organization")}
                                            placeholder=''
                                        />
                                        {showError && errors['organization'] && (
                                            <SzAlert variant="danger">{trans(errors['organization'])}</SzAlert>
                                        )}
                                    </div>
                                    <div className="col-5">
                                        <SzSelectWithLabel
                                            value={category}
                                            defaultValue={categoryOption}
                                            options={getCategoryOptions(organization)}
                                            name="category"
                                            onChange={(option: { label: string, value: string }) => {
                                                setFieldValue("category", option ? option.value : null);
                                            }}
                                            label={trans("category")}
                                            placeholder=''
                                        />
                                        {showError && errors['category'] && (
                                            <SzAlert variant="danger">{trans(errors['category'])}</SzAlert>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="public-work-box mt-3">
                                <SzTypographie variant="h1" className='m-0 mb-3 ml-5'>
                                    {trans('contact_title')}
                                </SzTypographie>

                                <div className="row ">
                                    <div className="col-6 ml-5 border-right pr-5">
                                        <div className="row ">
                                            <div className="col-6">
                                                <SzInput
                                                    label={trans("lastName")}
                                                    value={lastName}
                                                    onChange={(e) => {
                                                        setFieldValue("lastName", e.target.value);
                                                    }}
                                                />
                                                {showError && errors['lastName'] && (
                                                    <SzAlert variant="danger">{trans(errors['lastName'])}</SzAlert>
                                                )}
                                            </div>
                                            <div className="col-6">
                                                <SzInput
                                                    label={trans("firstName")}

                                                    value={firstName}
                                                    onChange={(e) => {
                                                        setFieldValue("firstName", e.target.value);
                                                    }}
                                                />
                                                {showError && errors['firstName'] && (
                                                    <SzAlert variant="danger">{trans(errors['firstName'])}</SzAlert>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-4">
                                        <div className="pl-5">
                                            <SzInput
                                                label={trans("mail")}
                                                value={mail}
                                                onChange={(e) => {
                                                    setFieldValue("mail", e.target.value);
                                                }}
                                                icon="envelope"
                                            />
                                            {showError && errors['mail'] && (
                                                <SzAlert variant="danger">{trans(errors['mail'])}</SzAlert>
                                            )}
                                            <div className="mt-3">
                                                <SzInput
                                                    label={trans("phone")}
                                                    value={phone}
                                                    onChange={(e) => {
                                                        setFieldValue("phone", e.target.value);
                                                    }}
                                                    icon="phone-actions-voice-mail"
                                                />
                                                {showError && errors['phone'] && (
                                                    <SzAlert variant="danger">{trans(errors['phone'])}</SzAlert>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <SzButton className={`float-right  ${!isValid ? 'text-disabled' : ''}`}
                                          onClick={() => {
                                              if(isValid) {
                                                  submitStep(values)
                                              }else
                                              {
                                                  setShowError(true);
                                              }
                                          }}>{trans('next')}</SzButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>

        </
            I18nextProvider>
    )
}
