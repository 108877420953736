import instanceRequest, {Request} from "./Request";

/**
 *
 * @export
 * @interface Nature
 */
export interface Nature {
    /**
     *
     * @type {string}
     * @memberof Nature
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof Nature
     */
    'label'?: string;
    /**
     *
     * @type {string}
     * @memberof Nature
     */
    'code'?: string;
}

class NatureService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getNatures() {
        const url = `/api/nature`;
        return this.axiosService.get(url, false).then((response: { data: Nature[] }) => {
            return response;
        });
    }
}

export default new NatureService(instanceRequest);
