import React, {useEffect, useState} from "react";
import {SzTypographie, SzButton, SzInput, SzDatePicker} from "@suezenv/react-theme-components";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import './index.scss'

interface IPublicWorkListFilter {
    createHandle: () => void,
    initData: (page?:number, filters?:{query:string,startDate:Date, endDate:Date} |null) => void
    filter: { query: string, startDate: Date, endDate: Date}
}

export const PublicWorkListFilter: React.FC<IPublicWorkListFilter> = ({createHandle, initData, filter}) => {
    const trans = useTranslationWithPrefix("publicWork", "view_public_work");
    const [startDate, setStartDate] = useState(filter.startDate);
    const [endDate, setEndDate] = useState(filter.endDate);
    const [search, setSearch] = useState(filter.query);
    const [dateError, setDateError] = useState<any>(null);

    const onChangeStartDate = (startDate: Date) => {
        setStartDate(startDate)
    }
    const onChangeEndDate = (endDate: Date) => {
        setEndDate(endDate)
    }

    useEffect(() => {
        setDateError(null);

        if(startDate <= endDate) {
            initData(1, {query: search, startDate, endDate})
        }
        else {
            setDateError(<div className="invalid-feedback" style={{display: 'block'}}>{trans('not_valid_daterange_picker')}</div>)
        }

    }, [startDate, endDate])

    return (
        <div className='mr-3 ml-3 mt-3 mb-0 public-work-header'>

            <div className="row">
                <div className="col-12">
                    <SzTypographie variant="h2" weight="bold" className="mt-3">
                        {trans('filter_date_range_text')}
                    </SzTypographie>
                </div>
            </div>
            
            <div className="row">
                <div className="col-xl-5 pb-2">
                    <SzDatePicker
                        startDate={startDate}
                        onChangeStartDate={onChangeStartDate}
                        endDate={endDate}
                        onChangeEndDate={onChangeEndDate}
                        dateRange
                        classNameStartDate="pl-3"
                        classNameEndDate="pr-3"
                    />
                    {dateError}
                </div>
                
                <div className="col-xl-4 d-flex">
                    <SzInput
                        name="search"
                        className="search-input"
                        value={search}
                        onChange={({target}) => setSearch(target.value)}
                        placeholder={trans('search_work_placeholder')}
                        onKeyUp ={(event: { key: string }) => {
                            if (event.key === "Enter") {
                                initData(1, {query: search, startDate, endDate})

                            }}
                        }
                        handleClear={() =>{
                            initData(1, {query: '', startDate, endDate})
                            setSearch('')}
                        } 
                    />
                    <SzButton icon="search" variant="secondary" onClick={() => initData(1, {query: search, startDate, endDate})} className="ml-1"/>
                </div>

                <div className="col-xl-3">
                    <div className="p-0 float-right">
                        <SzButton iconVariant="bold" icon="add-circle" onClick={createHandle} alignIcon="right">{trans('add_btn')}</SzButton>
                    </div>
                </div>
            </div>

        </div>
    );
};