import React from "react";
import i18n from "../../i18n";
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import store from "../../store";

interface IPublicWork {
}

export const PublicWork: React.FC<IPublicWork> = ({children}) => {
    return (
            <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                    <div className="public-work">
                        {children}
                    </div>
                </I18nextProvider>
            </Provider>
    );
};