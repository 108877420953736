import instanceRequest, {Request} from "./Request";
import {Nature} from "./NatureService";
import {Contact} from "./ContactService";
import {Address} from "./AddressService";
import {FeatureCollection} from "geojson";
import {PAGINATION_HEADERS} from "../constants";
import {IAttachment} from "../types";
import {formatDate, toLocalDate} from "../utile";

/**
 *
 * @export
 * @interface PublicWork
 */
export interface PublicWork {
    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    name?: string;

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    organizationId?: string;


    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    organizationName?: string;


    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    categoryId?: string;

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    categoryName?: string;

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    additionalInformation?: string;

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    startDate?: string;

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    endDate?: string;

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    geoJson?: FeatureCollection|null;

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    diDictNumber?: string;
    /**
     *
     * @type {Nature}
     * @memberof PublicWork
     */
    nature: Nature;

    /**
     *
     * @type {Contact}
     * @memberof PublicWork
     */
    contact: Contact;

    /**
     *
     * @type {Address}
     * @memberof PublicWork
     */
    address: Address

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    createdBy?: string;

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    simpleId?: string;

    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    updatedAt?: string;

    comments: {comment: string, createdAt: string, createdBy: string } [];
    attachments? : IAttachment []
    /**
     *
     * @type {string}
     * @memberof PublicWork
     */
    currentStatus?: string;
}


class PublicWorkService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getPublicWork(id:string) {
        const url = `/api/public-work/${id}`;
        return this.axiosService.get(url,false);
    }

    public postPublicWork(publicWork: PublicWork) {
        const url = `/api/public-work`;
        publicWork.startDate = publicWork.startDate ?toLocalDate(publicWork.startDate):undefined;
        publicWork.endDate = publicWork.endDate?toLocalDate(publicWork.endDate):undefined;
        return this.axiosService.post(url, {...publicWork, geoJson: JSON.stringify(publicWork.geoJson)});
    }


    public putPublicWork(publicWork: PublicWork) {
        const url = `/api/public-work/${publicWork.id}`;
        publicWork.startDate = publicWork.startDate ?toLocalDate(publicWork.startDate):undefined;
        publicWork.endDate = publicWork.endDate?toLocalDate(publicWork.endDate):undefined;
        return this.axiosService.put(url, {...publicWork, geoJson: JSON.stringify(publicWork.geoJson)});
    }

    public getPublicWorks(page = 1, filters : { query: string, startDate: Date, endDate:Date } | null = null, withPagination:boolean = true) {
        let params = '';
        if(filters)
        {
            params = `&query=${filters['query'] || ''}&startDate=${formatDate(filters['startDate'],'DD-MM-YYYY')||''}&endDate=${formatDate(filters['endDate'],'DD-MM-YYYY')||''}`;
        }

        const withPaginationParam = `&withPagination=${withPagination?1:0}`;
        const url = `/api/public-work?currentPage=${page}${params}${withPaginationParam}`;
        return this.axiosService.get(url, false).then((response: { data: PublicWork[], headers:any }) => {
            return {
                pagination: this.getPaginationInfo(response.headers),
                data: response.data,
            };
        });
    }

    public postPublicWorkAttachments(publicWork: PublicWork, attachments: IAttachment[]) {
        const url = `/api/public-work/${publicWork.id}/attachment`;
        return this.axiosService.post(url, {...publicWork, geoJson: JSON.stringify(publicWork.geoJson), attachments: JSON.stringify(attachments)});
    }

    public saveComment(publicWorkId?:string, comment?:string)
    {
        const url = `/api/public-work/${publicWorkId}/comment`;

        return this.axiosService.post(url, {comment: comment});
    }


    public getPaginationInfo(headers: any): any {
        const pagination: any = [];

        Object.entries(headers).map((header: any) => {
            if (PAGINATION_HEADERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
        });

        return pagination;
    }

}

export default new PublicWorkService(instanceRequest);
