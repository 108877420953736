import React, {useState} from "react";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import {SzData, SzInput, SzTypographie, SzTab, SzButton, SzAccordion} from "@suezenv/react-theme-components";
import {TileLayer} from "react-leaflet";
import {MAP_CENTER, MAP_URI} from "../../../constants";
import MapComponent from "../../mapComponent";
import {DetailBody} from "./DetailBody";
import {PublicWork as IPublicWork, PublicWork} from "../../../services/PublicWorkService";
import {drawFeatures} from "../../../hooks/drawGeojson";
import {LatLng} from "leaflet";
import {CommentList} from "./CommentList";
import PublicWorkService from "../../../services/PublicWorkService";
import {useDispatch} from "react-redux";
import {updatePublicWork} from "../../../store/actions/publicWork";
import {IAttachment} from "../../../types";
import AttachmentService from "../../../services/AttachmentService";
import { AddAttachments } from "./AddAttachments";

interface ISharedDetail {
    showCommentBlock: boolean,
    isCreationDetail: boolean,
    currentPublicWork?: PublicWork,
    geoserver?:{
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?:GeoJSON.Feature
}


export const SharedDetail: React.FC<ISharedDetail> = ({showCommentBlock, currentPublicWork, isCreationDetail, geoserver, geoShape}) => {
    const trans = useTranslationWithPrefix("publicWork", "public_work_create");
    const tranStatus = useTranslationWithPrefix("publicWork", "public_work_status");
    const [showDetail, setShowDetail] = useState("1");
    const [tabPj, setTabPj] = useState('tab-documents');
    const [comment, setComment] = useState('');
    const [toggleAddAttachments, setToggleAddAttachments] = useState(false);
    const dispatch = useDispatch();

    const formatJson = (json:any)=>{
        if(json && (typeof json === 'string'))
        {
            return JSON.parse(json);;
        }

        return json;
    }
    const geoJson = currentPublicWork ? formatJson(currentPublicWork.geoJson) : null;
    const downloadFile = (file: IAttachment) => {
        AttachmentService.getAttachment(currentPublicWork?.id, file?.id).then((response: any) => {
            const windowUrl = window.URL;
            const link = document.createElement("a");
            link.setAttribute("download", file.originalFileName);
            link.href = windowUrl.createObjectURL(new Blob([response.data]));
            document.body.appendChild(link);
            link.click();
        });
    };

    const getFiles = (files: IAttachment[]) => {
        return files.map((file: IAttachment, index: number) => {

            return (file.originalFileName && <li className='mt-1' key={index}>
                <span>
                    {file.originalFileName}
                    {!file.tmpDir && <SzButton variant="tertiary" className="mr-3" onClick={() => downloadFile(file)}
                                               icon="download-bottom"/>}
                </span>
            </li>);
        })
    }

    const saveComment = ()=>
    {
        const workId = currentPublicWork?.id;
        if(workId) {
            PublicWorkService.saveComment(workId, comment).then(() => {
                setComment('');
                PublicWorkService.getPublicWork(workId).then((response) => {

                    dispatch(updatePublicWork({...response.data}));
                })
            });
        }
    }

    const getFilesTab = () => {
        return (
            <SzTab.SzTabContent eventKey="tab-documents" title={trans('documents_tab_title')}>
                <div className="public-work-box">

                    {
                        !isCreationDetail && currentPublicWork && 
                        <SzAccordion activeKey={toggleAddAttachments == true ? "1" : "-1"} className="work-detail-accordion">
                            <SzAccordion.SzCollapse
                                header={<SzTypographie weight="medium" className="mb-1">{trans('add_attachments')}</SzTypographie>}
                                body={<AddAttachments currentPublicWork={currentPublicWork} />}
                                eventKey="1"
                                onClick={() => setToggleAddAttachments(!toggleAddAttachments)}
                            />
                        </SzAccordion>
                    }

                    <ul className="p-2 file-list">
                        {getFiles(formatJson(currentPublicWork?.attachments || []))}
                    </ul>

                </div>
            </SzTab.SzTabContent>
        );
    }

    const getCommentTab = () => {
        return showCommentBlock ? <SzTab.SzTabContent eventKey="tab-comment" title={trans('comment_tab_title')}>
            <div className="public-work-box">
                <div className="row mb-3">
                    <div className="col-5">
                        <SzInput
                            name="input-demo"
                            value={comment}
                            onChange={({target}) => setComment(target.value)}
                            label={trans("add_new_comment")}
                            placeholder={trans("add_new_comment")}
                        />
                    </div>
                    <div className="col-2 text-center align-self-end align-self-end p-2 pl-0">
                        <SzButton
                            variant="secondary"
                            icon="messages-bubble-edit"
                            alignIcon="right"
                            onClick={saveComment}
                            isDisabled={!comment}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-12">
                        <CommentList currentPublicWork={currentPublicWork} />
                    </div>
                </div>
            </div>
        </SzTab.SzTabContent> : <React.Fragment></React.Fragment>
    }

    return (<div className="mt-3">
            <div className="public-work-box mt-3">
                <div className="row pb-3">
                    <div className="col-3 border-right">
                        <SzData className="mx-4">
                            <SzData.Text>{trans('creator')}</SzData.Text>
                            <SzData.Title>{currentPublicWork?.createdBy}</SzData.Title>
                        </SzData>
                    </div>
                    <div className="col-3 border-right">
                        <SzData className="mx-4">
                            <SzData.Text>{trans('address_work')}</SzData.Text>
                            <SzData.Title>{currentPublicWork?.address.fullAddress} </SzData.Title>
                        </SzData>
                    </div>
                    <div className={`col-2 ${!isCreationDetail ? "border-right" : ""}`}>
                        <SzData className="mx-4">
                            <SzData.Text>{trans('category')}</SzData.Text>
                            <SzData.Title>{currentPublicWork?.categoryName||''}</SzData.Title>
                        </SzData>
                    </div>
                    {!isCreationDetail &&
                    <div className="col-2">
                        <SzData className="mx-4">
                            <SzData.Text>{trans('status')}</SzData.Text>
                            <SzData.Title><span className={`status-work-detail public-work-status ${currentPublicWork?.currentStatus ||'new'}-status mt-1 mr-1`}></span> {tranStatus(currentPublicWork?.currentStatus|| 'new')}</SzData.Title>
                        </SzData>
                    </div>
                    }

                    <div className="col-2">
                    </div>

                </div>
            </div>

            <div className="public-work-box mt-3">
                <SzAccordion activeKey={showDetail} className="work-detail-accordion">
                    <SzAccordion.SzCollapse
                        header={<SzTypographie weight="medium" className="mb-1">
                            {trans('hide_work_detail')}
                        </SzTypographie>}
                        body={<DetailBody currentPublicWork={currentPublicWork} geojson={currentPublicWork ? formatJson(currentPublicWork.geoJson):null}/>}
                        eventKey="1"
                        onClick={() => {
                            setShowDetail(showDetail === "1" ? "-1" : "1")
                        }}
                    />
                </SzAccordion>
            </div>
            <div className="mt-3 work-detail-box">
                <MapComponent zooms={{minZoom: 6, zoom: 9, maxZoom: 19}} center={MAP_CENTER} geoShape={geoShape}
                              workStatus = {currentPublicWork?.currentStatus||'new'}
                              geoserver={geoserver}
                              changeMarker={false}
                              mapPosition={currentPublicWork ? new LatLng(currentPublicWork.address.latitude || 0, currentPublicWork.address.longitude || 0):null}>
                    <TileLayer
                        url={MAP_URI}
                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {drawFeatures(geoJson)}
                </MapComponent>
            </div>
            <div className="mt-3 mb-3 work-detail-tab">
                <SzTab
                    activeKey={tabPj}
                    id="story-tab"
                    selectTabHandle={(index) => setTabPj(index)}
                >
                    {
                        getFilesTab()
                    }
                    {
                        getCommentTab()
                    }
                </SzTab>
            </div>
        </div>
    )
}