import * as Yup from 'yup';
import {phoneRegExp} from '../../constants';

export default {
    name: {
        schema: Yup.string().nullable().required('required_validation'),
        required: true
    },
    nature: {
        schema: Yup.string().nullable().required('required_validation'),
        required: true
    },
    additionalInformation: {
        schema: Yup.string().nullable(),
        placeholder: '',
        required: false
    }
};
