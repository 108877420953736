import Axios, {AxiosResponse} from 'axios'
import {LatLngTuple} from "leaflet";
import {config} from "../config";
import qs from "qs";


const MAP_REVERSE_API_URL = 'https://api-adresse.data.gouv.fr/reverse/?limit=1' // FIXME garder l'api mais la rendre paramétrable
const MAP_GEO_CODE_API_URL = 'https://api-adresse.data.gouv.fr/search/?q=' // FIXME garder l'api mais la rendre paramétrable
const NBR_CHAR_START_AUTO_COMPLETE = 3
const AUTO_COMPLETE_COUNTRY = 'France'
const CancelToken = Axios.CancelToken

let cancel: any

export interface IAddressAutoComplete {
    lat: number,
    lng: number,
    address: string,
    houseNumber: string,
    street: string,
    postcode: string,
    city: string,
    isGeocodeOk?: boolean,
    country?: string
}

export class MapGeoCodeService {
    public static cordinatesToAddress(lat: number, lng: number): Promise<IAddressAutoComplete> {
        let payload = {
            address: '',
            city: '',
            street: '',
            houseNumber: '',
            postcode: '',
            lat: lat,
            lng: lng,
            country: '',
            isGeocodeOk: false
        }
        return Axios.get(`${MAP_REVERSE_API_URL}&lat=${lat}&lon=${lng}`)
            .then((response) => {
                if (response.data.features.length !== 0) {
                    const {properties} = response.data.features[0]
                    const {city, housenumber, label, postcode, street} = properties
                    payload = {
                        address: label,
                        city: city,
                        street: street,
                        houseNumber: housenumber,
                        postcode: postcode,
                        lat: lat,
                        lng: lng,
                        country: AUTO_COMPLETE_COUNTRY,
                        isGeocodeOk: true
                    }
                }

                return payload;
            });
    }

    public static autoCompleteItemToAddress(
        lat: number,
        lng: number,
        street: string,
        houseNumber: string,
        postcode: string,
        city: string,
        address: string
    ): IAddressAutoComplete {
        const payload = {
            address: address,
            city: city,
            street: street,
            houseNumber: houseNumber,
            postcode: postcode,
            lat: lat,
            lng: lng,
            country: AUTO_COMPLETE_COUNTRY
        }
        return payload;
    }

    public static autoCompleteAddress(address: String): Promise<IAddressAutoComplete[]> {
        const items: IAddressAutoComplete [] = []
        if (address.length >= NBR_CHAR_START_AUTO_COMPLETE) {
            if (cancel !== undefined) {
                cancel()
            }
            return Axios.get(`${MAP_GEO_CODE_API_URL}${address}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                })
            }).then(function (response) {
                response.data.features.map((value: any): any => {
                    const item = {
                        lat: value.geometry.coordinates[1],
                        lng: value.geometry.coordinates[0],
                        address: value.properties.label,
                        houseNumber: value.properties.housenumber,
                        street: value.properties.street
                            ? value.properties.street
                            : value.properties.name,
                        postcode: value.properties.postcode,
                        city: value.properties.city
                    }
                    items.push(item)
                })
                return items;
            });
        }

        const promise = new Promise<void>(() => {
        });
        return promise.then(() => items);
    }


    public static isPointInDepartement = async (
        geoserver: {
            url: string;
            workspace: string;
            layer: string;
            authkey?: string;
        },
        point: LatLngTuple,
    ): Promise<boolean> => {
        const query = {
            version: "1.0.0",
            outputFormat: "application/json",
            typeName: `${geoserver.workspace}:${geoserver.layer}`,
            request: "GetFeature",
            service: "WFS",
            authKey: geoserver.authkey,
            CQL_FILTER: (`INTERSECTS(%geoserverPropertyName%, POINT(${point[1]} ${point[0]}))`)
                .replace('%geoserverPropertyName%', config.geoserverPropertyName),
        };
        const queryString = qs.stringify(query);
        return Axios.get(`${geoserver.url}/wfs?${queryString}`)
            .then((response: AxiosResponse<any>) => {
                return response.data.numberReturned > 0;
            });
    };
}