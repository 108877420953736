import React, {createRef, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {MapContainer, FeatureGroup, GeoJSON, WMSTileLayer, useMap, useMapEvents, Marker} from 'react-leaflet'
import {DragEndEvent, LatLngExpression} from 'leaflet'
import './mapComponent.scss'
import FitBoundMap from "./FitBoundMap";
import MapViewChanger from "./MapViewChanger";
import {MapPin} from "./icons/MapPin";

interface IMapComponent {
    children: any
    center?: LatLngExpression
    zooms?: any
    onClick?: Function
    disable?: boolean
    ref?: any
    workStatus?:string
    geoserver?: {
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?: GeoJSON.Feature,
    mapPosition?: LatLngExpression | null
    changeMarker?:boolean
    markerChangeHandle?: (lat: number, lng: number) => void

}

const MapComponent = (props: IMapComponent) => {

    const MAX_ZOOM = 18;
    const {children, center, zooms, onClick, geoShape, geoserver, mapPosition, changeMarker, markerChangeHandle, workStatus} = props;
    const [bounds, setBounds] = useState<any>(null);
    const featureGroupRef: any = useRef(null);
    const [position, setPosition] = useState<LatLngExpression>();

    useEffect(() => {
        setTimeout(function () {
            if (featureGroupRef.current) {
                setBounds(featureGroupRef.current.getBounds());
            }
        }, 1000)
    }, [featureGroupRef.current])

    const drawWMSTileLayer = () => {
        const showGeoshape = geoserver && geoserver.workspace && geoserver.url && geoserver.layer;
        if (geoserver && showGeoshape) {
            return <WMSTileLayer
                params={{
                    format: "image/png",
                    layers: `${geoserver.workspace}:${geoserver.layer}`,
                    transparent: true,
                    service: "WMS",
                }}
                url={`${geoserver.url}/wms?authkey=${geoserver.authkey}`}
                opacity={0.4}
                version="1.1.1"
            />
        }
    }

    useEffect(() => {
        if (mapPosition) {
            setPosition(mapPosition);
        }
    }, [mapPosition])

    const eventHandlers = useMemo(
        () => ({
            dragend(e: DragEndEvent) {
                const position = e.target.getLatLng()
                setPosition(e.target.getLatLng());
                if(markerChangeHandle) {
                    markerChangeHandle(position.lat, position.lng);
                }
            }
        }),
        []
    )

    const LocationMarker = () => {
        const map= useMap();
        if(changeMarker) {
            useMapEvents({
                click(e) {
                    setPosition(e.latlng)
                    map.setView(e.latlng, MAX_ZOOM);
                    if (markerChangeHandle) {
                        markerChangeHandle(e.latlng.lat, e.latlng.lng);
                    }
                }
            })
        }

        if(position)
        {
            map.setView(position, MAX_ZOOM);
        }
        return position ? (
            <Marker position={position} eventHandlers={eventHandlers} draggable = {changeMarker} icon={MapPin(workStatus||'new')}/>
        ) : null
    }

    return (
        <div className='my-map'>

            <MapContainer
                {...zooms}
                center={center}
                onclick={onClick}
                zoomControl={true}
                attributionControl={true}
                doubleClickZoom={true}
                scrollWheelZoom={true}
                dragging={true}
                animate={true}
                easeLinearity={0.35}
            >

                {children}

                {drawWMSTileLayer()}


                <FeatureGroup ref={featureGroupRef}>
                    {geoShape &&
                    <GeoJSON
                        key={Math.floor(Math.random() * (1000 + 1))}
                        data={geoShape}
                        style={() => ({
                            color: '#4689F7',
                            weight: 1.5,
                            fillOpacity: 0,
                            opacity: 0,
                            strokeWidth: '1'
                        })}
                    />
                    }
                </FeatureGroup>

                <LocationMarker/>
                {geoShape && bounds && !position &&
                <FitBoundMap bounds={bounds}/>
                }

            </MapContainer>
        </div>
    )
}

export default MapComponent
