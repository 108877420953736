import React, {useEffect, useState} from "react";
import {SzTypographie, SzStepper, SzButton} from "@suezenv/react-theme-components";
import {useTranslationWithPrefix} from "../../hooks/translation";
import {PublicWorkCreateStep1} from "./step/step1";
import {PublicWorkCreateStep2} from "./step/step2";
import {PublicWorkCreateStep3} from "./step/step3";
import {PublicWorkCreateStep4} from "./step/step4";
import {PublicWorkCreateStep5} from "./step/step5";
import {OrganizationType} from "../../types";
import {useQuery} from "react-query";
import NatureService, {Nature} from "../../services/NatureService";
import PublicWorkService, {PublicWork as IPublicWork} from "../../services/PublicWorkService";
import {UserType} from "../../types/user.type";
import {initPublicWork, updatePublicWork} from "../../store/actions/publicWork";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";

interface ICreateContent {
    organizations: OrganizationType[]
    goToHomeHandle: () => void,
    user: UserType,
    geoserver: {
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?: GeoJSON.Feature,
    workId?: string
}

export const CreateContent: React.FC<ICreateContent> = ({organizations, goToHomeHandle, geoserver, user, geoShape, workId}) => {
    const trans = useTranslationWithPrefix("publicWork", "public_work");
    const header = [trans('step_contact'), trans('step_identification'),
        trans('step_date_place'), trans('step_detail'), trans('step_summary')];
    const [step, setStep] = useState(0);
    const [natures, setNatures] = useState<Nature[]>([]);
    const dispatch = useDispatch();

    let currentPublicWork = workId ? useSelector((state: RootState) => state.publicWork.currentPublicWork):null;

    const nextStep = () => {
        if (step < 5) {
            setStep(step + 1);
        }
    }
    const previousStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    }

    const saveHandle = (currentPublicWork: IPublicWork) => {
        if(currentPublicWork.id)
        {
            PublicWorkService.putPublicWork(currentPublicWork).then(() => goToHomeHandle());

        }else
        {
            PublicWorkService.postPublicWork(currentPublicWork).then(() => goToHomeHandle());
        }
    }
    const fetchNatures = () => NatureService.getNatures();
    let {
        data: naturesData
    } = useQuery(['naturesData'], fetchNatures);

    useEffect(() => {
        if (workId) {
            PublicWorkService.getPublicWork(workId).then((response) => {
                dispatch(updatePublicWork({...response.data}))
            });
        } else {
            dispatch(initPublicWork());
        }

    }, [workId]);

    return (
        <div>
            <div className='mr-3 ml-3 mt-3 mb-0 p-2 pl-0 public-work-header border-left row'>
                <div className='col-5'>
                    <SzTypographie variant="h1" className='m-0 public-work-primary-text'>
                        {trans('public_work_title')}
                    </SzTypographie>
                    <SzTypographie className='m-0' color="inactive">
                        {trans('public_work_sub_title')}
                    </SzTypographie>
                </div>

                <div className='col-7'>
                    <SzTypographie variant="h1" className='mt-2'>
                        {trans('public_work_create_title')}
                    </SzTypographie>
                </div>
            </div>
            <div className="text-right mt-3 mr-3">
                <SzButton variant="tertiary" icon="bin-2-alternate"/>
            </div>
            <div className="m-3 mt-3">
                <SzStepper header={header} activeStep={step}>
                    <SzStepper.SzHeader/>
                    <SzStepper.SzStep className="m-0 p-0 pt-3" index={0}>
                        <div>
                            <PublicWorkCreateStep1 currentPublicWork={currentPublicWork} user={user} organizations={organizations} nextHandle={nextStep}/>
                        </div>
                    </SzStepper.SzStep>
                    <SzStepper.SzStep className="m-0 p-0 pt-3" index={1}>
                        <div>
                            <PublicWorkCreateStep2 currentPublicWork={currentPublicWork} natures={naturesData ? naturesData.data : []} nextHandle={nextStep}
                                                   previousHandle={previousStep}/>
                        </div>
                    </SzStepper.SzStep>
                    <SzStepper.SzStep className="m-0 p-0 pt-3" index={2}>
                        <div>
                            <PublicWorkCreateStep3 currentPublicWork={currentPublicWork} geoserver={geoserver} geoShape={geoShape} step={step}
                                                   nextHandle={nextStep} previousHandle={previousStep}/>
                        </div>
                    </SzStepper.SzStep>
                    <SzStepper.SzStep className="m-0 p-0 pt-3" index={3}>
                        <div>
                            <PublicWorkCreateStep4 currentPublicWork={currentPublicWork} nextHandle={nextStep} previousHandle={previousStep}/>
                        </div>
                    </SzStepper.SzStep>
                    <SzStepper.SzStep className="m-0 p-0 pt-3" index={4}>
                        <div>
                            <PublicWorkCreateStep5 geoserver={geoserver} geoShape={geoShape} step={step}
                                                   saveHandle={saveHandle} previousHandle={previousStep}/>
                        </div>
                    </SzStepper.SzStep>
                </SzStepper>
            </div>
        </div>
    );
};