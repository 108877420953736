import React, {useEffect, useState} from "react";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import {SzButton, SzData} from "@suezenv/react-theme-components";
import {SharedDetail} from "../../SharedComponent/Detail";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {PublicWork} from "../../../services/PublicWorkService";


interface IPublicWorkCreateStep5 {
    saveHandle: (currentPublicWork:PublicWork) => void
    previousHandle: () => void,
    step: number,
    geoserver:{
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?:GeoJSON.Feature
}


export const PublicWorkCreateStep5: React.FC<IPublicWorkCreateStep5> = ({saveHandle, previousHandle, step,geoShape, geoserver}) => {
    const trans = useTranslationWithPrefix("publicWork", "public_work_create");
    const [isLoaded, setIsLoaded] = useState(false);
    const currentPublicWork = useSelector((state: RootState) => state.publicWork.currentPublicWork);
    return (<React.Fragment> {(step === 4) && <div>
        <SzData icon="professions-man-construction-1">
            <SzData.Title>{currentPublicWork.name}</SzData.Title>
            <SzData.Text></SzData.Text>
        </SzData>
        {(step === 4) && <SharedDetail currentPublicWork={currentPublicWork} showCommentBlock={false} isCreationDetail={true} geoserver={geoserver} geoShape={geoShape} />}
        <div className="mt-3">
            <SzButton variant="secondary" className="float-left"
                      onClick={previousHandle}>{trans('back')}</SzButton>
            <SzButton className="float-right" onClick={()=>saveHandle(currentPublicWork)}>{trans('save')}</SzButton>
        </div>
    </div>}
    </React.Fragment>)
}