import React, {useEffect, useRef, useState} from 'react'
import {FeatureGroup, GeoJSON, TileLayer, WMSTileLayer} from 'react-leaflet'
import {MAP_CENTER, MAP_URI} from "../../../constants";
import MapComponent from "../../mapComponent";
import {EditControl} from "react-leaflet-draw";
import {SzButton} from "@suezenv/react-theme-components";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import L, {LatLngExpression} from "leaflet";
import {FeatureCollection} from "geojson";
import {ErrorBoundary} from 'react-error-boundary'

interface IDrawShape {
    onChangeMap: (geoJsonData: FeatureCollection | null) => void,
    geoJson: FeatureCollection | null,
    closeHandle: () => void,
    workStatus?:string
    geoserver: {
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?:GeoJSON.Feature,
    step:number,
    mapPosition?: LatLngExpression | null

}



export const DrawShape: React.FC<IDrawShape> = ({onChangeMap, geoJson, closeHandle, geoserver,geoShape, step, mapPosition, workStatus}) => {
    const zooms = {minZoom: 6, maxZoom: 18, zoom: 6}
    const [isLoaded, setIsLoaded] = useState(false);
    const trans = useTranslationWithPrefix("publicWork", "public_work_create");
    let editable: any = null;
    const [tmpGeoJson, setTmpGeojson] = useState<FeatureCollection |null>(geoJson);
    function ErrorHandler({error}: {error:any}) {
        return (
            <div role="alert">
                <p>An error occurred:</p>
                <pre>{error.message}</pre>
            </div>
        )
    }
    useEffect(() => {
            setIsLoaded(step ==2 );
    }, [step]);

    const onFeatureGroupReady = (reactFGref: any) => {
            let leafletGeoJSON = new L.GeoJSON(tmpGeoJson || undefined);

            let leafletFG = reactFGref;
            if (reactFGref) {
                leafletGeoJSON.eachLayer((layer) => {
                    leafletFG.addLayer(layer);
                });
                // store the ref for future access to content
                editable = reactFGref;
            }

    };

    const onChange = () => {
        try {
            if (!editable || !onChangeMap) {
                return;
            }
            const geoJsonData = editable.toGeoJSON();
            setTmpGeojson(geoJsonData);
        } catch (error) {
            console.log(error)
        }
    };

    const onDeleted = () => {
        onChange();
    };

    const onCreated = () => {
        onChange();
    };

    const onEdited = () => {
        onChange();
    };

    const saveHandle = () => {
        onChangeMap(tmpGeoJson);
        closeHandle();
    }

    return (
        <div className="public-work">
            <ErrorBoundary FallbackComponent={ErrorHandler}>
            {isLoaded &&
            <MapComponent  workStatus={workStatus} zooms={{...zooms}}  center={MAP_CENTER} geoShape={geoShape} geoserver={geoserver} mapPosition={mapPosition} changeMarker={false}>
                <TileLayer
                    url={MAP_URI}
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <FeatureGroup
                    ref={(reactFGref: any) => {
                        onFeatureGroupReady(reactFGref);
                    }}
                >
                    <EditControl
                        position='topright'
                        onEdited={onEdited}
                        onCreated={onCreated}
                        onDeleted={onDeleted}
                        draw={{
                            marker: false,
                            circlemarker: false,
                            polyline: false,
                            circle: false,
                            rectangle: false,
                        }}
                    />

                </FeatureGroup>

            </MapComponent>
            }
            <SzButton alignIcon="right"
                      className="float-right mt-3"
                      onClick={saveHandle}>
                {trans('done')}
            </SzButton>
                <SzButton variant="secondary" className="float-right mt-3 mr-3"
                          onClick={closeHandle}>{trans('cancel')}</SzButton>

        </ErrorBoundary>
        </div>
    )
}

