import * as Yup from 'yup';

export function getFormatedForm(model: any, defaultValues = []) {
    const schema = Yup.object().shape(
        ObjectFromEntries(
            Object.entries(model).map((value: any) => {
                return [value[0], value[1].schema];
            })
        )
    );
    return [schema];
}

const ObjectFromEntries = (entries: any): any => {
    let ret = [];
    for (let i = 0; i < entries.length; ++i) {
        ret[entries[i][0]] = entries[i][1];
    }
    return {...ret};
};

export {default as step1Model} from './createPublicWorkModels/step1Model';
export {default as step2Model} from './createPublicWorkModels/step2Model';
export {default as step3Model} from './createPublicWorkModels/step3Model';
export {default as step4Model} from './createPublicWorkModels/step4Model';
