import React, {useEffect, useState} from "react";
import {SzButton, SzDatePicker, SzTypographie, SzInput, SzAlert, SzModal} from "@suezenv/react-theme-components";
import {useDispatch, useSelector} from "react-redux";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import {Form, Formik} from "formik";
import {IAddressAutoComplete, MapGeoCodeService} from "../../../services/MapGeoCodeService";
import {autoCompleteAddressAction} from "../../../store/actions/geocode";
import {RootState} from "../../../store";
import AutoCompleteItemsComponent from "../../utile/AutoCompleteItems";
import {PublicWorkCreateStep3Map} from "./step3Map";
import {LatLng, LatLngExpression} from "leaflet";
import {DrawShape} from "./DrawShape";
import {FeatureCollection} from "geojson";
import {getFormatedForm, step3Model} from "../../../Models";
import {updatePublicWork} from "../../../store/actions/publicWork";
import {PublicWork} from "../../../services/PublicWorkService";


interface IPublicWorkCreateStep3 {
    nextHandle: () => void
    previousHandle: () => void,
    step: number,
    geoserver:{
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?:GeoJSON.Feature,
    currentPublicWork?:PublicWork
}

export const PublicWorkCreateStep3: React.FC<IPublicWorkCreateStep3> = ({nextHandle, previousHandle, step, geoserver, geoShape, currentPublicWork}) => {
    const trans = useTranslationWithPrefix("publicWork", "public_work_create");
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(false);
    const [showError, setShowError] = useState(false);
    const [schema] = getFormatedForm(step3Model);
    const addressAutoComplete = useSelector((state: RootState) => state.addressAutoComplete);
    const [showAutoCompleteItems, setShowAutoCompleteItems] = useState(false);
    const [isGeocodeOk, setIsGeocodeOk] = useState(true);
    const [addressText, setAddressText] = useState(currentPublicWork?.address?.fullAddress || '');
    const [lat, setLat] = useState<null | number>(currentPublicWork?.address?.latitude || null);
    const [lng, setLng] = useState<null | number>(currentPublicWork?.address?.longitude || null);
    const [houseNumber, setHouseNumber] = useState(currentPublicWork?.address?.number || '');
    const [postCode, setPostcode] = useState(currentPublicWork ? currentPublicWork?.address?.postCode : '');
    const [city, setCity] = useState(currentPublicWork ? currentPublicWork.address?.city : '');
    const [street, setStreet] = useState(currentPublicWork?.address?.street || '');
    const [mapPosition, setMapPosition] = useState<LatLngExpression | null>(null);
    const [showEditControl, setShowEditControl] = useState(false);
    const [geoJson, setGeoJson] = useState<FeatureCollection | null>(currentPublicWork?.geoJson || null);
    const [inContractArea, setInContractArea] = useState(false);


    useEffect(() => {
        if (lat && lng) {
            setMapPosition(new LatLng(lat, lng));
            MapGeoCodeService.isPointInDepartement(geoserver, [
                lat,
                lng,
            ]).then((isInDepartement: boolean) => {
                if (isInDepartement) {
                    setInContractArea(true);
                } else {
                    setInContractArea(false);
                }
            });
        }
    }, [lat, lng]);

    const autocompleteSearch = (value: string) => {
        MapGeoCodeService.autoCompleteAddress(value).then((response: IAddressAutoComplete[]) => {
            setIsGeocodeOk(true);
            dispatch(autoCompleteAddressAction(response))
        });
    }
    const submitStep = ({startDate, endDate}: PublicWork) => {
        dispatch(updatePublicWork({
            startDate,
            endDate,
            address: {
                fullAddress: addressText,
                latitude: lat,
                longitude: lng,
                number: houseNumber,
                postCode,
                city,
                street,
                country: 'France',
            },
            geoJson
        }));

        nextHandle();
    }
    const markerChangeHandle = (lat: number, lng: number) => {
        MapGeoCodeService.cordinatesToAddress(lat, lng).then((response: IAddressAutoComplete) => {
            if (response.isGeocodeOk) {
                setIsGeocodeOk(true);
                setAddressText(response.address);
                setLat(response.lat);
                setLng(response.lng);
                setHouseNumber(response.houseNumber);
                setPostcode(response.postcode);
                setCity(response.city);
                setStreet(response.street);
            }
        });
    }
    const onChangeMap = (geoJson: any) => {
        setGeoJson(geoJson)
    }

    const autoCompleteHandle = (e: { target: { value: string } }) => {
        if (e.target.value) {
            setAddressText(e.target.value);
            setShowAutoCompleteItems(true)
            autocompleteSearch(e.target.value);
        } else {
            setAddressText("");
            setShowAutoCompleteItems(false);
            setLat(null);
            setLng(null);
            setHouseNumber("");
            setPostcode("");
            setCity('');
            setStreet("");
        }
    }

    const autoCompleteClickHandle =
        (
            lat: number,
            lng: number,
            street: string,
            houseNumber: string,
            postcode: string,
            city: string,
            address: string
        ) => {
            setAddressText(address);
            setLat(lat);
            setLng(lng);
            setHouseNumber(houseNumber);
            setPostcode(postcode);
            setCity(city);
            setStreet(street);

            setShowAutoCompleteItems(false);
        }

    const closeHandle = () => {
        setShowEditControl(false)
    }
    return (
        <React.Fragment>
            <SzModal
                className={'draw-geo-shape-modal'}
                title={trans('draw_shape_title')}
                show={showEditControl}
                handleClose={closeHandle}
                size={'xl'}
                centered={true}
            >
                {showEditControl &&
                <DrawShape workStatus={currentPublicWork?.currentStatus}  mapPosition={mapPosition} closeHandle={closeHandle} geoserver={geoserver} step={step} geoShape={geoShape} geoJson={geoJson}
                           onChangeMap={onChangeMap}/>
                }
            </SzModal>
            {(step === 2) &&

            <Formik onSubmit={() => {
            }} initialValues={{
                startDate: currentPublicWork?.startDate ? new Date(currentPublicWork.startDate):null,
                endDate: currentPublicWork?.endDate ? new Date(currentPublicWork.endDate):null,
            }}>
                {(formikProps: any) => {
                    const {values, setFieldValue, errors} = formikProps;
                    const {
                        startDate,
                        endDate,
                    } = values;

                    schema.isValid(values).then((isValidForm: any) => {
                        setIsValid(isValidForm);
                    });

                    return (
                        <Form onSubmit={formikProps.handleSubmit}>
                            <div className="public-work-box mt-3 pl-2">
                                <SzTypographie variant="h1" className='m-0 mb-3 ml-5'>
                                    {trans('date_title')}
                                </SzTypographie>

                                <div className="row ml-5">
                                    <div className="col-2">
                                        <SzTypographie color="inactive" weight="bold" className="m-0 mt-3">
                                            {trans('date_label')} :
                                        </SzTypographie>
                                    </div>
                                    <div className="col-7">
                                        <SzDatePicker
                                            classNameEndDate='date-work'
                                            classNameStartDate='date-work'
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChangeStartDate={(date) => {
                                                setFieldValue("startDate", date);
                                            }}
                                            onChangeEndDate={(date) => {
                                                setFieldValue("endDate", date);
                                            }}
                                            dateRange
                                        />
                                        {showError && errors['startDate'] && (
                                            <SzAlert variant="danger">{trans(errors['startDate'])}</SzAlert>
                                        )}
                                        {showError && errors['endDate'] && (
                                            <SzAlert variant="danger">{trans(errors['endDate'])}</SzAlert>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="public-work-box mt-4 p-0">
                                <div className="row ml-5 m-0">
                                    <div className="col-5">
                                        <SzTypographie variant="h1" className='m-0 mb-3 mt-5 mb-5'>
                                            {trans('address_title')}
                                        </SzTypographie>


                                        <SzInput
                                            autocomplete
                                            onChange={(e) => {
                                                autoCompleteHandle(e)
                                            }}
                                            label={trans('auto_complete_address_label')}
                                            name='addressText'
                                            icon='search'
                                            placeholder={trans('auto_complete_address_placeholder')}
                                            value={addressText}
                                        />

                                        {(!isGeocodeOk && showError) && (
                                            <SzAlert variant='danger'>{trans('geocode_nok')}</SzAlert>
                                        )}
                                        {showAutoCompleteItems && (
                                            <AutoCompleteItemsComponent
                                                addressAutoComplete={addressAutoComplete}
                                                autoCompleteClickHandle={autoCompleteClickHandle}
                                            />
                                        )}
                                        {lng && lat && !inContractArea && (
                                            <SzAlert variant='danger'>
                                                {trans('not_in_contract_area')}
                                            </SzAlert>
                                        )}

                                        <div className="row mt-5">
                                            <div className="col-6">
                                                <SzInput
                                                    readOnly
                                                    placeholder=''
                                                    label={trans("city")}
                                                    value={city}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <SzInput
                                                    readOnly
                                                    placeholder=''
                                                    label={trans("postCode")}
                                                    value={postCode+''}
                                                />
                                            </div>
                                        </div>

                                        <SzInput
                                            readOnly
                                            placeholder=''
                                            label={trans("street")}
                                            value={street}
                                        />

                                        <div className="row">
                                            <div className="col-6">
                                                <SzInput
                                                    readOnly
                                                    placeholder=''
                                                    label={trans("houseNumber")}
                                                    value={houseNumber}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <div className="invisible">
                                                    <SzInput
                                                        readOnly
                                                        placeholder=''
                                                        label={trans("lat")}
                                                        value={lat ? lat + '' : ''}
                                                    />
                                                    <SzInput
                                                        readOnly
                                                        placeholder=''
                                                        label={trans("lng")}
                                                        value={lng ? lng + '' : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <SzButton icon="maps-pin-1" alignIcon="right"
                                                  variant="secondary"
                                                  className="float-right mb-3"
                                                  onClick={() => setShowEditControl(!showEditControl)}
                                        >{trans('draw_area')}</SzButton>
                                    </div>
                                    <div className="col-7 p-0">
                                        <PublicWorkCreateStep3Map showEditControl={showEditControl}
                                                                  workStatus={currentPublicWork?.currentStatus}
                                                                  markerChangeHandle={markerChangeHandle}
                                                                  mapPosition={mapPosition}
                                                                  geoJson={geoJson}
                                                                  geoserver={geoserver}
                                                                  geoShape={geoShape}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <SzButton variant="secondary" className="float-left"
                                          onClick={previousHandle}>{trans('back')}</SzButton>
                                <SzButton className={`float-right  ${(!isValid || !geoJson || !inContractArea ) ? 'text-disabled' : ''}`}
                                          onClick={() => {
                                              if(isValid && geoJson && inContractArea) {
                                                  submitStep(values)
                                              }else
                                              {
                                                  setShowError(true);
                                              }
                                          }}>{trans('next')}</SzButton>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            }
        </React.Fragment>

    )
}