import * as Yup from 'yup';
import {phoneRegExp} from '../../constants';

export default {
    startDate: {
        schema: Yup.string().nullable().required('required_validation'),
        required: true
    },
    endDate: {
        schema: Yup.string().nullable().required('required_validation'),
        required: true
    },
    fullAddress: {
        schema: Yup.string().nullable(),
        required: false
    },
    city: {
        schema: Yup.string().nullable(),
        required: false
    },
    street: {
        schema: Yup.string().nullable(),
        required: false
    },
    number: {
        schema: Yup.string().nullable(),
        required: false
    },
    postCode: {
        schema: Yup.string().nullable(),
        required: false
    },
    latitude: {
        schema: Yup.string().nullable(),
        required: false
    },
    longitude: {
        schema: Yup.string().nullable(),
        required: false
    }
};
