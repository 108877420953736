import React, {useEffect, useState} from "react";
import {SharedDetail} from "../SharedComponent/Detail";
import {PublicWork} from "../utile/PublicWork";
import {SzButton, SzData, SzTypographie, SzModal, SzSpinner} from "@suezenv/react-theme-components";
import {useTranslationWithPrefix} from "../../hooks/translation";
import PublicWorkService from "../../services/PublicWorkService";
import {useDispatch, useSelector} from "react-redux";
import {updatePublicWork} from "../../store/actions/publicWork";
import {RootState} from "../../store";
import PublicWorkStatusService from "../../services/PublicWorkStatusService";
import {UserType} from "../../types/user.type";
import {PUBLIC_SPACE_PLANNER_OPERATOR_SERVICE} from "../../constants";


interface IPublicWorkDetail {
    goToHome: () => void,
    editHandle: (id?:string) => void,
    user:UserType,
    workId:string,
    geoserver:{
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?:GeoJSON.Feature
}


export const DetailContent: React.FC<IPublicWorkDetail> = ({goToHome, workId, geoShape, geoserver, editHandle, user}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const dispatch = useDispatch();
    const trans = useTranslationWithPrefix("publicWork", "public_work_detail");
    const tranStatus = useTranslationWithPrefix("publicWork", "public_work_status");
    const currentPublicWork = useSelector((state: RootState) => state.publicWork.currentPublicWork);
    const [transitions,setTransitions] = useState([]);
    const [modal, setModal] = useState<any>(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if(workId) {
            PublicWorkService.getPublicWork(workId).then((response)=>{
                dispatch(updatePublicWork({...response.data}))
                setIsLoaded(true);
            });
            getPublicWorkStatusTransitions(workId);
        }

    }, [workId]);

    const isCreatedBySameUser = () => {
        return `${user.data?.firstName} ${user.data?.lastName}` === currentPublicWork.createdBy;
    }

    const getPublicWorkStatusTransitions = (workId:string)=>{
      PublicWorkStatusService.getPublicWorkStatusTransitions(workId).then((data)=>{
          setTransitions(data ? Object.values(data) : []);
      })
    }

    const changeStatusHandle =  (transition:string)=> {
        closeModalHandle();
        setLoader(true);
        PublicWorkStatusService.putPublicWorkStatus(workId, transition).then(()=>{
            getPublicWorkStatusTransitions(workId);
            PublicWorkService.getPublicWork(workId).then((response)=>{
                dispatch(updatePublicWork({...response.data}))
            }).finally(()=>{
                setLoader(false);
            });
        });
    }

    const closeModalHandle = () => {
        setModal(null);
    }

    const buildStatusChangeModal = (transition:string) => {
        const content = (
            <React.Fragment>
                <p>{trans('please_confirm_status_change')}</p>
                <SzButton variant="secondary" onClick={() => changeStatusHandle(transition)} className="mt-1">
                    {trans('i_confirm')}
                </SzButton>
            </React.Fragment>
        )

        setModal({title: 'Changement de statut à "'+tranStatus(transition)+'"', size: 'lg', content});
    }

    const canChangeStatus = ()=>{
        const listServices = Object.keys(user?.data?.services||[]);
        return listServices.includes(PUBLIC_SPACE_PLANNER_OPERATOR_SERVICE);
    }
    const showWorkFlowBtns =  ():any=>{
       return canChangeStatus () ? transitions.map((transition:string) =>
           <SzButton variant="secondary" isDisabled={loader} loader={loader} onClick={() => buildStatusChangeModal(transition)} className="mr-3">
                {tranStatus(transition)}
            </SzButton>
        ):<React.Fragment/>
    }

    return (
        <React.Fragment>
            <SzModal
                className={'draw-status-change-modal'}
                title={modal?.title}
                show={modal ? true : false}
                handleClose={() => closeModalHandle()}
                size={modal?.size}
                centered={true}
            >
                {modal?.content}
            </SzModal>

            {isLoaded &&
                <PublicWork>

                    <div className='mr-3 ml-3 mt-3 mb-0 p-2 pl-0 public-work-header border-left row'>
                        <div className='col-5'>
                            <SzButton
                                variant="tertiary"
                                className="mr-3"
                                icon="keyboard-arrow-left"
                                alignIcon="left"
                                onClick={goToHome}
                            >
                                {trans('back')}
                            </SzButton>
                        </div>

                        <div className='col-7'>
                            <SzTypographie variant="h1" className='mt-2'>
                                {trans('public_work_detail_title')}
                            </SzTypographie>
                        </div>
                    </div>
                    <div className="mt-5 ml-3 mr-3 row">

                        <div className="col-5">
                            <SzData icon="professions-man-construction-1">
                                <SzData.Title>{currentPublicWork.name}</SzData.Title>
                                <SzData.Text>{currentPublicWork.simpleId}</SzData.Text>
                            </SzData>
                        </div>
                        <div className="col-7 ">
                            <div className="d-flex flex-row-reverse">
                                {showWorkFlowBtns()}
                                {isCreatedBySameUser() && (currentPublicWork.currentStatus !== "closed") &&
                                <SzButton onClick={() => editHandle(currentPublicWork.id)} className="mr-3">
                                    {trans('edit_public_work')}
                                </SzButton>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ml-3 mr-3">
                        <SharedDetail geoShape={geoShape} geoserver={geoserver} showCommentBlock={true}
                                    currentPublicWork={currentPublicWork} isCreationDetail={false}/>
                    </div>
                </PublicWork>
            }
        </React.Fragment>)
}