import {FeatureCollection, Position} from "geojson";
import {Polygon} from "react-leaflet";
import React from "react";


export const drawFeatures = (geoJson?:FeatureCollection|null) => {

    if (geoJson && geoJson.features) {
        return geoJson.features.map((coordinate: any, index: string | number | undefined) => {
            return coordinate.geometry && <Polygon
                key={index}
                positions={convertGeoJsonCoordinateToLatLong(coordinate.geometry.coordinates)}
                attribution="puprle"
            />;
        });
    }

    return null;
};

const convertGeoJsonCoordinateToLatLong = (coordinates: Position[]) => {
    const convertCoords: any[] = [];
    let convertCoor: any[] = [];
    coordinates.forEach((coordinate: Position) => {
            convertCoor = [];
            coordinate.forEach(
                (point: any) =>
                    convertCoor.push([point[1], point[0]]),
            );
            convertCoords.push(convertCoor);
        },
    );
    return convertCoords;
};