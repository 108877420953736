import React from "react";
import {UserType} from "../../types/user.type";
import {PublicWork} from "../utile/PublicWork";
import {SzTypographie} from "@suezenv/react-theme-components";
import {useTranslationWithPrefix} from "../../hooks/translation";
import {GeoJSON} from "react-leaflet";
import {PublicWorkViewTabs} from "./PublicWorkTabs";

interface IPublicWorkView {
    user: UserType
    createHandle: () => void,
    goToDetail: (publicWorkId?: string) => void,
    geoserver: {
        url: string,
        workspace: string,
        layer: string,
        authkey: string,
    },
    geoShape?: GeoJSON.Feature,
}

export const PublicWorkView: React.FC<IPublicWorkView> = ({user, createHandle, goToDetail, geoserver, geoShape}) => {

    const trans = useTranslationWithPrefix("publicWork", "public_work");
    return (
        <PublicWork>
            <div className="public-work">
                <div className='mr-3 ml-3 mt-3 mb-0 p-2 pl-0 public-work-header border-left row'>
                    <div className='col-5'>
                        <SzTypographie variant="h1" className='m-0 public-work-primary-text'>
                            {trans('public_work_title')}
                        </SzTypographie>
                        <SzTypographie className='m-0' color="inactive">
                            {trans('public_work_sub_title')}
                        </SzTypographie>
                    </div>
                </div>
                <PublicWorkViewTabs geoserver={geoserver} geoShape={geoShape} goToDetail={goToDetail}
                                    createHandle={createHandle}/>
            </div>
        </PublicWork>
    );
};