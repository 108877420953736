import React from "react";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import {SzData, SzTypographie} from "@suezenv/react-theme-components";
import {PublicWork} from "../../../services/PublicWorkService";
import {formatDate} from "../../../utile";
import  area from '@turf/area'
import  {polygon} from '@turf/turf'


interface DetailBody {
    currentPublicWork?: PublicWork,
    geojson?:any
}

export const DetailBody: React.FC<DetailBody> = ({currentPublicWork, geojson}) => {

    const measureArea = () =>
    {
        if(!geojson)
        {
            return 0;
        }


        let totalArea = 0;
        geojson.features.forEach((feature:any)=>
        {
            let  lnglats:any = [];
            feature.geometry.coordinates.forEach((cord:any)=>{
                lnglats.push(cord)
            });
            const polygonFeature = polygon(lnglats);
            totalArea += area(polygonFeature);
        });



        return totalArea.toFixed(2)
    }
    const trans = useTranslationWithPrefix("publicWork", "public_work_create");

    return (<div>
        <div className="work-detail-box p-3">
            <SzTypographie variant="h1">{trans('actor_detail_title')}</SzTypographie>
            <div className="row p-3">
                <div className="col-4 border-right">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('organization')}</SzData.Text>
                        <SzData.Title>{currentPublicWork?.organizationName}</SzData.Title>
                    </SzData>
                    <SzData className="mx-3 mt-4">
                        <SzData.Text>{trans('contact_title')}</SzData.Text>
                        <SzData.Title>{`${currentPublicWork?.contact.firstName || ''} ${currentPublicWork?.contact.lastName || ''}`}</SzData.Title>
                    </SzData>
                </div>
                <div className="col-4 border-right">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('category')}</SzData.Text>
                        <SzData.Title>{currentPublicWork?.categoryName}</SzData.Title>
                    </SzData>
                    <SzData className="mx-3 mt-4">
                        <SzData.Text>{trans('mail')}</SzData.Text>
                        <SzData.Title>{currentPublicWork?.contact.mail}</SzData.Title>
                    </SzData>
                </div>
                <div className="col-4">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('phone')}</SzData.Text>
                        <SzData.Title>{currentPublicWork?.contact.phone}</SzData.Title>
                    </SzData>
                </div>
            </div>
        </div>
        <div className="work-detail-box p-3 mt-3">
            <SzTypographie variant="h1">{trans('work_detail_title')}</SzTypographie>
            <div className="row p-3">
                <div className="col-6 border-right">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('name')}</SzData.Text>
                        <SzData.Title>{currentPublicWork?.name}</SzData.Title>
                    </SzData>
                </div>
                <div className="col-6">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('nature')}</SzData.Text>
                        <SzData.Title>{currentPublicWork?.nature.label}</SzData.Title>
                    </SzData>
                </div>
            </div>
            <SzData className="mx-3 w-100 p-3">
                <SzData.Text>{trans('additionalInformation_work')}</SzData.Text>
                <SzData.Title>{currentPublicWork?.additionalInformation}</SzData.Title>
            </SzData>
            {
                /*<SzTypographie variant="h1" className="mt-3">{trans('impact_public_work')}</SzTypographie>
            <div className="d-flex flex-row">
                <div className="border-right">
                    <SzData className="mx-3 w-100 p-3">
                        <SzData.Title>[Transport]</SzData.Title>
                    </SzData>
                    <SzData className="mx-3 w-100 p-3">
                        <SzData.Title>[Bruit]</SzData.Title>
                    </SzData>
                </div>
                <div className="border-right">
                    <SzData className="mx-3 w-100 p-3">
                        <SzData.Title>[Énergie]</SzData.Title>
                    </SzData>
                    <SzData className="mx-3 w-100 p-3">
                        <SzData.Title>[Eau]</SzData.Title>
                    </SzData>
                </div>
                <div>
                    <SzData className="mx-3 w-100 p-3">
                        <SzData.Title>[Assainissement]</SzData.Title>
                    </SzData>
                </div>
            </div>
            */
            }
        </div>

        <div className="work-detail-box p-3 mt-3">
            <SzTypographie variant="h1">{trans('date_label')}</SzTypographie>
            <div className="row p-3">
                <div className="col-4 border-right">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('start_date')}</SzData.Text>
                        <SzData.Title>{formatDate(currentPublicWork?.startDate)}</SzData.Title>
                    </SzData>
                </div>
                <div className="col-4">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('end_date')}</SzData.Text>
                        <SzData.Title>{formatDate(currentPublicWork?.endDate)}</SzData.Title>
                    </SzData>
                </div>
                {
                   /* <div className="col-4">
                        <SzData className="mx-3">
                            <SzData.Text>{trans('work_same_city_title')}</SzData.Text>
                            <SzData.Title>3 {trans('other_work')}</SzData.Title>
                        </SzData>
                    </div>*/
                }
            </div>
        </div>

        <div className="work-detail-box p-3 mt-3">
            <SzTypographie variant="h1">{trans('work_local_title')}</SzTypographie>
            <div className="row p-3">
                <div className="col-3 border-right">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('street_work')}</SzData.Text>
                        <SzData.Title>{currentPublicWork?.address.street}</SzData.Title>
                    </SzData>
                </div>
                <div className="col-3 border-right">
                    <SzData className="mx-3 ">
                        <SzData.Text>{trans('post_code_work')}</SzData.Text>
                        <SzData.Title>{currentPublicWork?.address.postCode}</SzData.Title>
                    </SzData>
                </div>
                <div className="col-3">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('city_work')}</SzData.Text>
                        <SzData.Title>{currentPublicWork?.address.city}</SzData.Title>
                    </SzData>
                </div>
                <div className="col-3">
                    <SzData className="mx-3">
                        <SzData.Text>{trans('area_work')}</SzData.Text>
                        <SzData.Title>{measureArea()} m2</SzData.Title>
                    </SzData>
                </div>
            </div>
        </div>

    </div>)
}