import instanceRequest, {Request} from "./Request";


class PublicWorkStatusService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public putPublicWorkStatus(publicWorkId: string, statusTransition: string) {
        const url = `/api/public-work/${publicWorkId}/status`;
        return this.axiosService.put(url, {transition: statusTransition});
    }

    public getPublicWorkStatusTransitions(publicWorkId:string){
        const url = `/api/public-work/${publicWorkId}/statusWorkflow/transitions`;
        return this.axiosService.get(url, false).then((response: any) => {
            return response.data;
        });
    }

}

export default new PublicWorkStatusService(instanceRequest);
