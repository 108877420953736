import moment from "moment";
import {Polygon} from "react-leaflet";

export const formatDate = (date: Date | string | undefined, format = 'DD/MM/YYYY') => {
    return date ? moment(date).format(format) : "-";

};

export const toLocalDate = (date: Date| string, format?:string ) => {
    return moment(date).local().format(format);
};

