import React from "react";

const NewPinWork: React.FC = () => {
    return (
        <svg width="42" height="53.1" viewBox="0 0 68 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dd_1929_183642)">
                <path fillRule="evenodd" clipRule="evenodd" d="M42.9792 57.3877C53.4394 53.6788 60.931 43.6971 60.931 31.9655C60.931 17.0729 48.8582 5 33.9655 5C19.0729 5 7 17.0729 7 31.9655C7 43.697 14.4916 53.6787 24.9517 57.3877L33.9655 73L42.9792 57.3877Z" fill="#DEB861"/>
                <path d="M42.9792 57.3877L42.1437 55.0315C41.583 55.2303 41.1116 55.6225 40.8141 56.1377L42.9792 57.3877ZM24.9517 57.3877L27.1167 56.1377C26.8193 55.6225 26.3479 55.2302 25.7872 55.0314L24.9517 57.3877ZM33.9655 73L31.8004 74.25C32.247 75.0235 33.0723 75.5 33.9655 75.5C34.8586 75.5 35.6839 75.0235 36.1305 74.25L33.9655 73ZM58.431 31.9655C58.431 42.6055 51.6375 51.6652 42.1437 55.0315L43.8147 59.744C55.2412 55.6925 63.431 44.7886 63.431 31.9655H58.431ZM33.9655 7.5C47.4775 7.5 58.431 18.4536 58.431 31.9655H63.431C63.431 15.6922 50.2389 2.5 33.9655 2.5V7.5ZM9.5 31.9655C9.5 18.4536 20.4536 7.5 33.9655 7.5V2.5C17.6922 2.5 4.5 15.6922 4.5 31.9655H9.5ZM25.7872 55.0314C16.2934 51.6651 9.5 42.6055 9.5 31.9655H4.5C4.5 44.7886 12.6897 55.6924 24.1162 59.7439L25.7872 55.0314ZM36.1305 71.75L27.1167 56.1377L22.7866 58.6377L31.8004 74.25L36.1305 71.75ZM40.8141 56.1377L31.8004 71.75L36.1305 74.25L45.1443 58.6377L40.8141 56.1377Z" fill="white"/>
            </g>
            <g clipPath="url(#clip0_1929_183642)">
                <path fillRule="evenodd" clipRule="evenodd" d="M44.5082 25.4116C44.4652 25.3186 44.3712 25.2596 44.2682 25.2616H42.1682C42.0422 25.2466 41.9472 25.1386 41.9482 25.0116V24.4116C41.9472 24.3256 41.9932 24.2446 42.0682 24.2016C43.1462 23.5826 43.5182 22.2076 42.8992 21.1306C42.2812 20.0526 40.9062 19.6806 39.8282 20.2986C38.7502 20.9176 38.3782 22.2926 38.9972 23.3706C39.1952 23.7166 39.4822 24.0026 39.8282 24.2016C39.9032 24.2446 39.9492 24.3256 39.9482 24.4116V25.0116C39.9482 25.1496 39.8362 25.2616 39.6982 25.2616H39.5682C39.5262 25.2476 39.4802 25.2476 39.4382 25.2616L31.9182 32.8316C31.8482 32.9066 31.8252 33.0146 31.8582 33.1116C31.9012 33.2046 31.9952 33.2636 32.0982 33.2617H36.8282C36.8762 33.2606 36.9222 33.2426 36.9582 33.2116L44.4482 25.6916C44.5182 25.6166 44.5412 25.5086 44.5082 25.4116Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M46.2682 26.2416C46.1832 26.2136 46.0902 26.2366 46.0282 26.3016L39.4482 32.8316C39.3682 32.9016 39.3442 33.0156 39.3882 33.1116C39.4282 33.2026 39.5182 33.2616 39.6182 33.2616H41.9482C42.0862 33.2616 42.1982 33.3736 42.1982 33.5116V42.7616C42.1982 43.4516 42.7582 44.0116 43.4482 44.0116C44.1382 44.0116 44.6982 43.4516 44.6982 42.7616V33.5116C44.6982 33.3736 44.8102 33.2616 44.9482 33.2616C45.7762 33.2616 46.4482 32.5896 46.4482 31.7616V26.7616C46.4582 26.6486 46.4582 26.5346 46.4482 26.4216C46.4272 26.3326 46.3572 26.2626 46.2682 26.2416Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M29.5682 25.4116C29.5282 25.3206 29.4382 25.2616 29.3382 25.2616H29.1982C29.0602 25.2616 28.9482 25.1496 28.9482 25.0116V24.4116C28.9472 24.3256 28.9932 24.2446 29.0682 24.2016C30.1462 23.5826 30.5182 22.2076 29.8992 21.1306C29.2812 20.0526 27.9062 19.6806 26.8282 20.2986C25.7502 20.9176 25.3782 22.2926 25.9972 23.3706C26.1952 23.7166 26.4822 24.0026 26.8282 24.2016C26.9032 24.2446 26.9492 24.3256 26.9482 24.4116V25.0116C26.9482 25.1496 26.8362 25.2616 26.6982 25.2616H23.9482C23.1202 25.2616 22.4482 25.9336 22.4482 26.7616V31.7616C22.4512 31.9176 22.4752 32.0716 22.5182 32.2216C22.5482 32.3016 22.6152 32.3606 22.6982 32.3816C22.7832 32.4036 22.8732 32.3806 22.9382 32.3216L29.5682 25.6916C29.6232 25.6066 29.6232 25.4966 29.5682 25.4116Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M37.0282 25.4116C36.9882 25.3206 36.8982 25.2616 36.7982 25.2616H32.1882C32.1492 25.2476 32.1072 25.2476 32.0682 25.2616L24.1582 33.2216V42.7016C24.1582 43.3916 24.7182 43.9516 25.4082 43.9516C26.0982 43.9516 26.6582 43.3916 26.6582 42.7016V33.5116C26.6582 33.3736 26.7702 33.2616 26.9082 33.2616H29.2982C29.3532 33.2686 29.4082 33.2506 29.4482 33.2116L36.9682 25.6916C37.0482 25.6216 37.0722 25.5076 37.0282 25.4116Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_dd_1929_183642" x="0.5" y="0.5" width="66.931" height="83" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.372549 0 0 0 0 0.984314 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1929_183642"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.372549 0 0 0 0 0.984314 0 0 0 0.05 0"/>
                    <feBlend mode="normal" in2="effect1_dropShadow_1929_183642" result="effect2_dropShadow_1929_183642"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1929_183642" result="shape"/>
                </filter>
                <clipPath id="clip0_1929_183642">
                    <rect width="25" height="25" fill="white" transform="translate(22.4482 20)"/>
                </clipPath>
            </defs>
        </svg>


    )
}

export default NewPinWork;
