import React, {useState} from "react";
import {SzAlert, SzButton, SzInput, SzTypographie} from "@suezenv/react-theme-components";
import i18n from "../../../i18n";
import {I18nextProvider} from "react-i18next";
import {useTranslationWithPrefix} from "../../../hooks/translation";
import {Form, Formik} from "formik";
import SzSelectWithLabel from "../../utile/SzSelectWithLabel";
import {Nature} from "../../../services/NatureService";
import {getFormatedForm, step2Model} from "../../../Models";
import {updatePublicWork} from "../../../store/actions/publicWork";
import {useDispatch, useSelector} from "react-redux";
import {PublicWork} from "../../../services/PublicWorkService";
import {RootState} from "../../../store";


interface IPublicWorkCreateStep2 {
    nextHandle: () => void
    previousHandle: () => void,
    natures: Nature[],
    currentPublicWork?:PublicWork
}

export const PublicWorkCreateStep2: React.FC<IPublicWorkCreateStep2> = ({nextHandle, previousHandle, natures, currentPublicWork}) => {
    const trans = useTranslationWithPrefix("publicWork", "public_work_create");
    const [isValid, setIsValid] = useState(false);
    const [showError, setShowError] = useState(false);
    const [schema] = getFormatedForm(step2Model);
    const dispatch = useDispatch();

    const submitStep = ({name, nature, additionalInformation}: PublicWork) => {
        dispatch(updatePublicWork({
            name,
            nature,
            additionalInformation
        }));

        nextHandle();
    }

    const getLabelNatureById = (id: string) => {
        let label = null;
        natures.forEach((nature: Nature) => {

            if (nature.id === id) {
                label = nature.label;
            }
        });

        return label;
    }

    const getNatureOptions = () => {
        const naturesOptions: { label: string, value: string }[] = [];
        natures.forEach((nature: Nature) => {
            naturesOptions.push({value: nature.id || '', label: nature.label || ''});
        });

        return naturesOptions;
    }
    return (
        <I18nextProvider i18n={i18n}>
            <Formik onSubmit={() => {
            }} initialValues={{
                name:currentPublicWork?.name ? currentPublicWork.name: '',
                natureOption: currentPublicWork?.nature.id ? { label: currentPublicWork.nature.label, value: currentPublicWork.nature.id }:null,
                nature: currentPublicWork?.nature.id ? currentPublicWork.nature.id: null,
                additionalInformation: currentPublicWork?.additionalInformation ? currentPublicWork.additionalInformation: '',
            }}
                    validationSchema={schema}>
                {(formikProps: any) => {
                    const {values, setFieldValue, errors, setFieldError, setErrors} = formikProps;
                    const {
                        name,
                        nature,
                        additionalInformation,
                        natureOption
                    } = values;

                    schema.isValid(values).then((isValidForm: any) => {
                        setIsValid(isValidForm);
                    });

                    return (
                        <Form onSubmit={formikProps.handleSubmit}>
                            <div className="public-work-box">
                                <div className="row  justify-content-center">
                                    <div className="col-5">
                                        <SzInput
                                            placeholder={trans('write_name')}
                                            label={trans("name")}
                                            value={name}
                                            onChange={(e) => {
                                                setFieldValue("name", e.target.value);
                                            }}
                                            icon="pencil-write"
                                        />
                                        {showError && errors['name'] && (
                                            <SzAlert variant="danger">{trans(errors['name'])}</SzAlert>
                                        )}
                                        <SzSelectWithLabel
                                            value={nature}
                                            defaultValue={natureOption}
                                            options={getNatureOptions()}
                                            name="type"
                                            onChange={(option: { label: string, value: string }) => {
                                                setFieldValue("nature", option ? option.value : null);
                                            }}
                                            label={trans("nature_work")}
                                            placeholder=''
                                        />
                                        {showError && errors['nature'] && (
                                            <SzAlert variant="danger">{trans(errors['nature'])}</SzAlert>
                                        )}
                                        <SzInput
                                            label={trans("additionalInformation")}
                                            value={additionalInformation}
                                            onChange={(e) => {
                                                setFieldValue("additionalInformation", e.target.value);
                                            }}
                                        />
                                        {showError && errors['additionalInformation'] && (
                                            <SzAlert variant="danger">{trans(errors['additionalInformation'])}</SzAlert>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <SzButton variant="secondary" className="float-left"
                                          onClick={previousHandle}>{trans('back')}</SzButton>
                                <SzButton className={`float-right  ${!isValid ? 'text-disabled' : ''}`}
                                          onClick={() => {
                                              if (isValid) {
                                                  submitStep({
                                                      ...values,
                                                      nature: {
                                                          id: nature,
                                                          label: getLabelNatureById(nature)
                                                      }


                                                  })
                                              } else {
                                                  setShowError(true);
                                              }
                                          }}>{trans('next')}</SzButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>

        </
            I18nextProvider>
    )
}