declare global {
    interface Window {
        REACT_APP_API_PUBLIC_SPACE_PLANNER_HOST: any;
        REACT_APP_GEOSERVER_PROPERTY_NAME:any
    }
}

const apiUrl = window.REACT_APP_API_PUBLIC_SPACE_PLANNER_HOST || process.env.REACT_APP_API_PUBLIC_SPACE_PLANNER_HOST;
let geoserverPropertyName = window.REACT_APP_GEOSERVER_PROPERTY_NAME || process.env.REACT_APP_GEOSERVER_PROPERTY_NAME || "";

export const config = {
    apiUrl: apiUrl,
    geoserverPropertyName: geoserverPropertyName
};
