import * as Yup from 'yup';
import {phoneRegExp} from '../../constants';

export default {
    organization: {
        schema: Yup.string().nullable().required('required_validation'),
        required: true
    },
    category: {
        schema: Yup.string().nullable().required('required_validation'),
        required: true
    },
    lastName: {
        schema: Yup.string().nullable().required('required_validation'),
        required: true
    },
    firstName: {
        schema: Yup.string().nullable(),
        placeholder: '',
        required: false
    },
    mail: {
        schema: Yup.string().nullable().email('not_valid_email').required('required_validation'),
        required: true
    },
    phone: {
        schema: Yup.string().nullable().matches(phoneRegExp, 'not_valid_phone').required('required_validation'),
        required: true
    }
};
